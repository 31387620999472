import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import "./Pos.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addOutline,
  arrowForwardOutline,
  bagCheckOutline,
  barcode,
  cardOutline,
  cart,
  cashOutline,
  checkmarkOutline,
  closeOutline,
  person,
  personAdd,
  printOutline,
  readerOutline,
  search,
  swapVerticalOutline,
} from "ionicons/icons";
import AppContext from "./../context/App/AppContext";
import Header from "../components/Header";
import {
  Category,
  Product,
  Customer,
  LineItem,
  Order,
  Variation,
  Attributes,
  ProductVariations,
} from "./../modals/modals";
import ProductItem from "../components/pos/ProductItem";
import {
  getCategories,
  getProductos,
  isCategoryOfProduct,
  sendOrderFunction,
  getPriceIVA,
  imageUrl,
  getIVA,
  getTotal,
  getFormatter,
  getDiscount,
  getDiscountPercent,
  removeAccent,
  getOrders,
  compareOrderState,
  getAllVariations,
  returnLineItemV4,
  formatDateISO8601,
} from "../hooks/functions";
import AddClient from "../components/pos/AddClient";
import useSound from "use-sound";
//import boopSfx from '../../public/assets/sounds/notificacion.mp3';
import { format } from "date-fns";
import printJS from "print-js";
import SearchText from "../components/pos/SearchText";

const Pos: React.FC = () => {
  //Subido a github2
  /*alert(window.browser.runtime.getManifest().version);*/
  const { userAuth, openPresent } = useContext(AppContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [showProducts, setShowProducts] = useState(0);
  const [charging, setCharging] = useState<boolean>(true);

  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [searchTextCustomer, setSearchTextCustomer] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [prodPage, setProdPage] = useState<number>(1);
  //const [cart, serCart] = useState<Cart | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const [lineItem, setLineItem] = useState<LineItem[]>([]);
  const [operations, setOperations] = useState<number>(0);
  const [shipping, setShipping] = useState<string>("local_pickup");
  const [showModal, setShowModal] = useState(false);
  const [showModalCustomer, setShowModalCustomer] = useState(false);
  const [showModalCustomerAdd, setShowModalCustomerAdd] = useState(false);
  const [showModalVariation, setShowModalVariation] = useState(false);
  const [payMethod, setPayMethod] = useState("cash");
  const [boleta, setBoleta] = useState(true);
  const [cash, setCash] = useState(0);
  //const [isPaid, setIsPaid] = useState('paid');
  const history = useHistory();
  const cashInput = useRef<HTMLIonInputElement>(null);
  const [shippingCost, setShippingCost] = useState("0");
  const [completeOrder, setCompleteOrder] = useState(false);
  const [errorInSend, setErrorInSend] = useState<string>("");
  //const shippingCostInput = useRef<HTMLIonInputElement>(null);
  const [discountType, setDiscountType] = useState("percent");
  const [discount, setDiscount] = useState("0");
  const [loadDate, setLoadDate] = useState("");
  const [playNotification] = useSound("assets/sounds/notificacion.mp3");
  const [playAdd] = useSound("assets/sounds/add.mp3");
  const [playError] = useSound("assets/sounds/error.m4a");
  const [variations, setVariations] = useState<ProductVariations | null>(null);
  const [presentAlert] = useIonAlert();
  //const [timeoutS, setTimeoutS] = useState<NodeJS.Timeout>();
  if (!userAuth) {
    history.replace("/login");
  }
  let date: Date = new Date();
  //console.log(date);

  let subtotal = 0;
  let authToken = "Bearer " + userAuth?.token;
  let storeToken = "Bearer " + userAuth?.store_token;
  // Create our number formatter.
  let orders: Order[] = [];
  let pageOrders = 1;
  let pageOrdersTotals = 1;
  let variationsLog: Variation[] = [];

  /*Comprobar si hay pedidos vía web */
  const checkOrders = async () => {
    let now: Date = new Date();
    await getOrders(storeToken, pageOrders, loadDate, formatDateISO8601(now))
      .then((response) => {
        pageOrdersTotals = response.headers["x-wp-totalpages"];
        let notification = false;
        let countOrders = 0;
        if (response.data.length > 0) {
          response.data.map((o: Order) => {
            let orderDate = new Date(o.date_created);

            if (
              o.created_via != "rest-api" &&
              orders.findIndex((or) => or.id == o.id) == -1 &&
              date.getTime() < orderDate.getTime()
            ) {
              notification = true;
              o.line_items.map((li: LineItem) => {
                let index = products.findIndex((p) => p.id == li.product_id);

                products[index].stock_quantity -= li.quantity;
                setOperations(operations + 1);
              });
              countOrders = countOrders + 1;
            } else if (
              o.created_via != "rest-api" &&
              date.getTime() < orderDate.getTime()
            ) {
              let order1 = orders.find((or) => or.id == o.id);
              if (compareOrderState(order1 ? order1 : o, o) < 0) {
                o.line_items.map((li: LineItem) => {
                  let index = products.findIndex((p) => p.id == li.product_id);
                  products[index].stock_quantity -= li.quantity;
                  setOperations(operations + 1);
                });
              } else if (compareOrderState(order1 ? order1 : o, o) > 0) {
                o.line_items.map((li: LineItem) => {
                  let index = products.findIndex((p) => p.id == li.product_id);
                  products[index].stock_quantity += li.quantity;
                  setOperations(operations + 1);
                });
              }
            }
          });
          orders = response.data;
        }
        if (notification) {
          openPresent(
            "Nuevo(s) pedido(s) vía web (" + countOrders + ")",
            false,
            "secondary",
            "https://toppet.cl/store-manager/orderslist/",
            900000
          );
          //audio.play();
          playNotification();
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadDate != "") {
        checkOrders();
      }
    }, 12000);
    return () => clearInterval(interval);
  }, [loadDate]);

  //Cargar Productos en el POS
  const loadProducts = async () => {
    setCharging(true);
    await getProductos(
      userAuth?.client_key + "",
      userAuth?.client_secret + "",
      prodPage
    )
      .then(async (response) => {
        let newProducts = products.concat(response.data);
        setProducts(newProducts);
        if (prodPage < response.headers["x-wp-totalpages"]) {
          let newProdPage = prodPage + 1;
          setProdPage(newProdPage);
        } else {
          await getCategories(
            userAuth?.client_key + "",
            userAuth?.client_secret + "",
            0
          )
            .then(async (responseCategories) => {
              let newCatArr: Category[] = [];
              responseCategories.data.map(async (cat: Category) => {
                await getCategories(
                  userAuth?.client_key + "",
                  userAuth?.client_secret + "",
                  cat.id
                ).then((newCats) => {
                  newCatArr.push(cat);
                  newCatArr = newCatArr.concat(newCats.data);
                  setCategories(newCatArr);
                });
              });
              setCharging(false);
              setLoadDate(formatDateISO8601(date));
            })
            .catch((error) => {
              openPresent(error.toString(), true, "danger", "", 3000);
            });
        }
      })
      .catch((error) => {
        presentAlert({
          header: "Error de sincronización",
          message:
            "No se pudo establecer conexión con el servidor. Por favor pulse Reintentar",
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
            },
            {
              text: "Reintentar",
              role: "confirm",
              handler: () => {
                loadProducts();
              },
            },
          ],
          backdropDismiss: false,
        });
      });
  };
  const loadCustomer = async () => {
    setCharging(true);
    await axios
      .get(
        "https://toppet.cl/wp-json/wc/v3/customers/?search=" +
          searchTextCustomer,
        {
          auth: {
            username: userAuth?.client_key + "",
            password: userAuth?.client_secret + "",
          },
        }
      )
      .then((customersRes) => {
        setCustomerList(customersRes.data);
        setCharging(false);
      })
      .catch((error) => {
        openPresent(error.toString(), true, "danger", "", 3000);
      });
  };
  useEffect(() => {
    if (charging) {
      loadProducts();
    }
  }, [prodPage]);
  useEffect(() => {
    /*if (searchTextCustomer.length < 3) {
      setCustomerList([]);
    } else*/ if (searchTextCustomer.length >= 3) {
      loadCustomer();
    }
  }, [searchTextCustomer]);

  useEffect(() => {
    let sp = document.querySelectorAll(".col-product-card.visible");
    setShowProducts(sp.length);
    searchTextFocus();
  }, [searchTextFilter, products]);
  const addProductToCart = (prod: Product, variation: Variation | null) => {
    if (prod.type == "variable" && variation == null) {
      let pv: ProductVariations = { product: prod, variations: [] };
      setOperations(prod.variations.length);
      setVariations(pv);
      setCharging(true);
      setVariations(null);
      setShowModalVariation(true);
      getAllVariations(
        userAuth?.client_key + "",
        userAuth?.client_secret + "",
        prod.id
      ).then((res) => {
        let pv: ProductVariations = { product: prod, variations: res };
        setVariations(pv);
        setCharging(false);
      });
    } else {
      let tempLIA = returnLineItemV4(
        lineItem,
        prod,
        variation,
        playAdd,
        playError
      );
      setLineItem(tempLIA);
      setOperations(operations + 1);
    }
  };
  const delProductCart = (index: number) => {
    let tempLIA = lineItem;
    tempLIA.splice(index, 1);
    setLineItem(tempLIA);
    setOperations(operations + 1);
  };
  const delAllProductCart = () => {
    setLineItem([]);
    setOperations(0);
  };
  const modProductCartItem = (index: number, add: boolean) => {
    let tempLIA = lineItem;
    if (add) {
      tempLIA[index].quantity = tempLIA[index].quantity + 1;
    } else {
      tempLIA[index].quantity > 1
        ? (tempLIA[index].quantity = tempLIA[index].quantity - 1)
        : (tempLIA[index].quantity = tempLIA[index].quantity);
    }
    setLineItem(tempLIA);
    setOperations(operations + 1);
    playAdd();
  };
  const resetPos = () => {
    upgradeStock();
    //setProducts([]);
    setCustomer(null);
    setShipping("local_pickup");
    setShippingCost("0");
    setOperations(0);
    //setCharging(true);
    setCompleteOrder(false);
    setCash(0);
    setErrorInSend("");
    setDiscount("0");
    //setProdPage(1);
    //set
  };
  const upgradeStock = () => {
    let indexP = -1;
    lineItem.map((li: LineItem, index) => {
      indexP = products.findIndex((p) => p.id == li.product_id);
      if (products[indexP].manage_stock) {
        products[indexP].stock_quantity -= li.quantity;
      }
    });
    setLineItem([]);
  };
  const sendOrder = async () => {
    setCharging(true);
    await sendOrderFunction(
      authToken,
      lineItem,
      payMethod,
      userAuth,
      customer,
      shipping,
      shippingCost + "",
      getDiscountPercent(discountType, subtotal, +discount) + ""
    )
      .then((response) => {
        setCompleteOrder(true);
        setOperations(response?.data.id);

        setCharging(false);
      })
      .catch((error) => {
        openPresent(error.toString(), true, "danger", "", 3000);
        setErrorInSend("Ups! Ocurrió un error.");
        setCharging(false);
      });
  };
  const finishOrder = () => {
    setShowModal(true);
  };

  useEffect(() => {
    //setCharging(false);
  }, [searchTextFilter]);

  const returnCash = () => {
    setCash(cashInput.current?.value as number);
  };
  /*const changeShipping=()=>{
    setShippingCost(shippingCostInput.current?.value as number);
  }*/
  const searchTextFocus = () => {
    if (!isSearch) {
      document.querySelector<HTMLInputElement>("#pos-search input")?.focus();
    }
  };
  const changeDiscount = (input: string) => {
    if (discountType === "percent") {
      +input > 100 ? setDiscount("100") : setDiscount(input);
    } else {
      +input > getTotal(subtotal, +shippingCost)
        ? setDiscount(getTotal(subtotal, +shippingCost) + "")
        : setDiscount(input);
    }
    if (input === "") setDiscount("0");
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonHeader className="pos-tools ion-no-border">
          <IonGrid>
            <IonRow>
              <IonCol sizeMd="8">
                <IonRow>
                  <IonCol>
                    <SearchText
                      isSearch={isSearch}
                      setIsSearch={setIsSearch}
                      products={products}
                      setSearchTextFilter={setSearchTextFilter}
                      charging={charging}
                      setCharging={setCharging}
                      addProductToCart={addProductToCart}
                    />
                  </IonCol>
                  <IonCol sizeMd="12" sizeLg="4">
                    <IonToolbar>
                      <IonSelect
                        disabled={categories.length < 1}
                        interface="popover"
                        value={category}
                        placeholder="Seleccione una categoría"
                        onIonChange={(e) => {
                          setCategory(e.detail.value);
                          searchTextFocus();
                        }}
                        onIonFocus={(e) => searchTextFocus()}
                      >
                        <IonSelectOption value={0}>
                          Todas las Categorías
                        </IonSelectOption>
                        {categories.map((c: Category) => {
                          return (
                            <IonSelectOption key={c.id} value={c.id}>
                              {c.parent_id ? " -- " : ""}
                              {c.name}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </IonToolbar>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol sizeMd="4" className="ion-hide-md-down">
                <IonToolbar>
                  <IonItem
                    lines="none"
                    button
                    onClick={() => {
                      setShowModalCustomer(true);
                      setIsSearch(true);
                    }}
                    onFocus={(e) => searchTextFocus()}
                    disabled={charging}
                  >
                    <IonIcon color="medium" icon={person} />
                    <IonLabel className="ion-text-no-wrap">
                      &nbsp;&nbsp;
                      {customer ? (
                        <IonText>
                          {customer.first_name + " " + customer.last_name}{" "}
                          <small> {customer.email}</small>
                        </IonText>
                      ) : (
                        "Invitado"
                      )}
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    lines="none"
                    button
                    slot="end"
                    onClick={() => {
                      setCustomer(null);
                      setShippingCost("0");
                      setShipping("local_pickup");
                    }}
                  >
                    <IonIcon color="dark" icon={closeOutline} />
                  </IonItem>
                  <IonItem
                    lines="none"
                    button
                    slot="end"
                    onClick={() => {
                      setShowModalCustomerAdd(true);
                      setIsSearch(true);
                    }}
                    disabled={charging}
                  >
                    <IonIcon color="success" icon={personAdd} />
                  </IonItem>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>

        <IonGrid className="absolute-grid">
          <IonRow>
            <IonCol sizeMd="8" className="max-height">
              <IonRow>
                {showProducts < 1 && (
                  <IonCol>
                    <IonItem>
                      <IonLabel>No hay productos para mostrar</IonLabel>
                    </IonItem>
                  </IonCol>
                )}
                {products.map((p: Product, index) => {
                  let catBoolean = true;
                  if (category != 0) {
                    catBoolean = isCategoryOfProduct(category, p.categories);
                  }
                  return ((p.stock_status === "instock" &&
                    p.manage_stock === false) ||
                    (p.stock_quantity > 0 && p.manage_stock === true)) &&
                    catBoolean ? (
                    <IonCol
                      key={index}
                      sizeXs="6"
                      sizeMd="4"
                      sizeLg="3"
                      sizeXl="2"
                      className={
                        !isSearch ||
                        !searchTextFilter ||
                        removeAccent(p.name)
                          .toLowerCase()
                          .indexOf(
                            removeAccent(searchTextFilter).toLowerCase()
                          ) > -1
                          ? "col-product-card visible"
                          : "col-product-card ion-hide"
                      }
                      onClick={() => {
                        addProductToCart(p, null);
                      }}
                    >
                      <ProductItem product={p} />
                    </IonCol>
                  ) : (
                    ""
                  );
                })}
              </IonRow>
              <div className="footer ion-justify-content-center ion-align-items-center">
                {charging ? (
                  <IonSpinner color="primary" slot="start" name="dots" />
                ) : (
                  ""
                )}
                &nbsp;{" "}
                <IonBadge slot="start">
                  {" "}
                  Mostrando {showProducts} de {products.length} Productos
                </IonBadge>
              </div>
            </IonCol>
            <IonCol sizeMd="4" className="ion-hide-md-down max-height">
              <IonCard className="ion-no-margin cart max-height">
                <IonCardContent className="max-height ch-flex">
                  <div className="line-item">
                    <table className="line-item-table">
                      <thead>
                        <tr>
                          <th>Producto</th>
                          <th>Cant</th>
                          <th>Precio</th>
                          <th>
                            <IonButton
                              color="danger"
                              size="small"
                              onClick={() => delAllProductCart()}
                            >
                              <IonIcon icon={cart} />
                            </IonButton>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lineItem.map((li: LineItem, index) => {
                          //linePrice=
                          subtotal =
                            subtotal + (li.price ? +li.price : 0) * li.quantity;
                          //totalTax = totalTax + (+li.subtotal_tax) * li.quantity;
                          return (
                            <tr key={index}>
                              <td>
                                <IonLabel title={li.name}>{li.name}</IonLabel>
                              </td>
                              <td>
                                <IonButton
                                  color="medium"
                                  size="small"
                                  disabled={li.quantity > 1 ? false : true}
                                  onClick={() =>
                                    modProductCartItem(index, false)
                                  }
                                >
                                  -
                                </IonButton>{" "}
                                <IonText>&nbsp;{li.quantity}&nbsp;</IonText>{" "}
                                <IonButton
                                  color="success"
                                  size="small"
                                  disabled={
                                    li.quantity <
                                      Number(
                                        products.find(
                                          (el) => el.id == li.product_id
                                        )?.stock_quantity
                                      ) ||
                                    Number(
                                      products.find(
                                        (el) => el.id == li.product_id
                                      )?.stock_quantity
                                    ) == 0
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    modProductCartItem(index, true)
                                  }
                                >
                                  +
                                </IonButton>
                              </td>
                              <td>
                                {getFormatter(
                                  getPriceIVA(
                                    +(li.price ? +li.price : 0).toFixed(0)
                                  )
                                )}
                              </td>
                              <td>
                                <IonButton
                                  color="danger"
                                  size="small"
                                  onClick={() => delProductCart(index)}
                                >
                                  x
                                </IonButton>
                              </td>
                            </tr>
                          );
                        })}
                        {lineItem.length > 0 ? (
                          ""
                        ) : (
                          <tr>
                            <td colSpan={4} className="ion-align-center">
                              Carrito vacío
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <IonToolbar className="first-toolbar">
                    <IonLabel slot="start">
                      Subtotal{" "}
                      <span>
                        (Imp {getFormatter(+((subtotal * 19) / 100).toFixed(0))}
                        )
                      </span>
                    </IonLabel>
                    <IonTitle slot="end" size="small">
                      {getFormatter(+getPriceIVA(subtotal).toFixed(0))}
                    </IonTitle>
                  </IonToolbar>
                  <IonToolbar id="toolbar-shipping" color="light">
                    <IonLabel slot="start">Envío:</IonLabel>
                    <IonSelect
                      slot="start"
                      value={shipping}
                      onIonChange={(e) => {
                        setShipping(e.detail.value);
                      }}
                    >
                      <IonSelectOption value="local_pickup">
                        Recogida Local
                      </IonSelectOption>
                      {customer ? (
                        <IonSelectOption value="flat_rate">
                          Con Envío
                        </IonSelectOption>
                      ) : (
                        ""
                      )}
                    </IonSelect>
                    <IonLabel className="" slot="end">
                      Costo: $
                    </IonLabel>
                    {shipping == "flat_rate" ? (
                      <IonInput
                        onIonChange={(e) =>
                          setShippingCost(e.detail.value as string)
                        }
                        value={shippingCost}
                        min="0"
                        slot="end"
                        className="toolbar-shipping-end"
                        type="number"
                        onClick={() => setIsSearch(true)}
                      ></IonInput>
                    ) : (
                      <IonInput
                        value="0"
                        min="0"
                        slot="end"
                        className="toolbar-shipping-end"
                        type="number"
                        readonly
                        disabled
                      ></IonInput>
                    )}
                  </IonToolbar>
                  <IonToolbar className="" id="toolbar-discount">
                    <IonLabel slot="start">Descuento:</IonLabel>
                    <IonSelect
                      slot="start"
                      value={discountType}
                      onIonChange={(e) => {
                        setDiscountType(e.detail.value);
                        setDiscount("0");
                      }}
                    >
                      <IonSelectOption value="percent">
                        Por ciento
                      </IonSelectOption>
                      <IonSelectOption value="fixed">Fijo</IonSelectOption>
                    </IonSelect>
                    <IonLabel className="" slot="end">
                      -{" "}
                      {getFormatter(
                        +getDiscount(discountType, discount, subtotal)
                      )}
                    </IonLabel>
                    <IonInput
                      onIonChange={(e) =>
                        changeDiscount(e.detail.value as string)
                      }
                      value={discount}
                      min="0"
                      max={
                        discountType === "percent"
                          ? "100"
                          : "" + getTotal(subtotal, +shippingCost)
                      }
                      slot="end"
                      className="toolbar-shipping-end"
                      type="number"
                      onClick={() => setIsSearch(true)}
                      disabled={lineItem.length < 1}
                    ></IonInput>
                  </IonToolbar>
                  <IonButton
                    id="pay-now"
                    color="warning"
                    expand="full"
                    size="large"
                    onClick={() => {
                      finishOrder();
                      setIsSearch(true);
                    }}
                    disabled={lineItem.length > 0 && !charging ? false : true}
                    onFocus={(e) => searchTextFocus()}
                  >
                    <IonLabel slot="start" className="ion-margin-end">
                      Pagar Ahora
                      <IonIcon icon={arrowForwardOutline} />
                    </IonLabel>
                    <IonLabel slot="end" className="ion-margin-start">
                      <IonText>
                        <small>
                          Total:{" "}
                          {getFormatter(
                            getTotal(subtotal, +shippingCost) -
                              +getDiscount(discountType, discount, subtotal)
                          )}
                        </small>
                      </IonText>
                      <IonText>
                        <small>
                          (Imp.){" "}
                          {getFormatter(
                            getIVA(
                              getTotal(subtotal, +shippingCost) -
                                +getDiscount(discountType, discount, subtotal)
                            )
                          )}
                        </small>
                      </IonText>
                    </IonLabel>
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={showModal}
          className="order-modal"
          backdropDismiss={false}
        >
          <IonContent>
            <IonHeader>
              <IonToolbar color="dark">
                <IonTitle>Opciones para el Pago</IonTitle>
                <IonButton
                  className="ion-padding-end"
                  color="light"
                  slot="end"
                  onClick={() => {
                    setShowModal(false);
                    setIsSearch(false);
                  }}
                  disabled={completeOrder || charging}
                >
                  {" "}
                  <IonIcon color="dark" icon={closeOutline} />{" "}
                </IonButton>
              </IonToolbar>
            </IonHeader>
            {completeOrder ? (
              <IonGrid className="grid-to-print">
                <IonRow>
                  <IonCol className="ion-text-center">
                    <div id="to-print" className="ion-text-left">
                      <table>
                        <tr>
                          <td colSpan={3}>TOPPET SPA</td>
                        </tr>

                        <tr>
                          <td colSpan={3}>77.045.978-8</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            SUCURSAL: {userAuth?.store_display_name}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="border-bottom">
                            {userAuth?.store_address}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>DETALLES DEL RECIBO</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            Vendedor: {userAuth?.user_display_name}
                          </td>
                        </tr>
                        {customer ? (
                          <>
                            <tr>
                              <td colSpan={3}>
                                Cliente:{" "}
                                {customer.first_name + " " + customer.last_name}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                Teléfono: {customer.billing.phone}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                Dirección: {customer.billing.address_1}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td colSpan={3}>
                            Fecha: {format(new Date(), "yyyy-MM-dd hh:mm:ss")}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Pedido: {operations}</td>
                        </tr>
                        <tr>
                          <th className="pt-3">Producto</th>
                          <th className="pt-3">Precio</th>
                          <th className="pt-3">Cant</th>
                        </tr>
                        {lineItem.map((li: LineItem, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <small>{li.name}</small>
                              </td>
                              <td>
                                <small>
                                  {getFormatter(
                                    getPriceIVA(+(li.price ? +li.price : 0))
                                  )}
                                </small>
                              </td>
                              <td>
                                <small>{li.quantity}</small>
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan={3} className="pt-3">
                            <strong>
                              Monto Total:
                              {" " +
                                getFormatter(
                                  getTotal(subtotal, +shippingCost) -
                                    +getDiscount(
                                      discountType,
                                      discount,
                                      subtotal
                                    )
                                )}
                            </strong>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={3}>
                            Envío:
                            {" " + getFormatter(+shippingCost)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            Descuento:
                            {" " +
                              getFormatter(
                                +getDiscount(discountType, discount, subtotal)
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            El IVA incluido:
                            {" " +
                              getFormatter(
                                getIVA(
                                  getTotal(subtotal, +shippingCost) -
                                    +getDiscount(
                                      discountType,
                                      discount,
                                      subtotal
                                    )
                                )
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="ion-text-center">
                            <img
                              className="ion-no-margin-bottom"
                              src="assets/images/qrcodetoppetcl.png"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="ion-text-center">
                            <strong>WWW.TOPPET.CL</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <small>
                              Documento válido para realizar devolución, cambio
                              o como medio de comprobación.
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <small>
                              Recuerde presentar este documento junto con su
                              cédula de identidad.
                            </small>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </IonCol>
                  <IonCol className="col-complete-buton ion-text-center ion-align-items-center">
                    <IonButton
                      size="large"
                      expand="full"
                      color="dark"
                      onClick={() => {
                        printJS({
                          printable: "to-print",
                          type: "html",
                          documentTitle: "TOPPET",
                          maxWidth: 200,
                          style:
                            "#to-print{font-size:8px !important;line-height:1.2;max-width:200px}#to-print td{font-size:8px !important;}.m-5{margin-top:3px;margin-bottom:3px;}#to-print img{width:130px;margin-left:auto;margin-right:auto;}td{border:0}td.border-bottom{padding-bottom: 3px;border-bottom:2px solid black;}th{text-align:left}td.pt-3,th.pt-3{padding-top:7px}#to-print table{width:200px;max-width:200px;}",
                        });
                      }}
                    >
                      <IonIcon slot="start" icon={printOutline} /> Imprimir{" "}
                    </IonButton>
                    <IonButton
                      size="large"
                      expand="full"
                      color="warning"
                      onClick={() => {
                        setIsSearch(false);
                        setShowModal(false);
                        resetPos();
                      }}
                    >
                      <IonIcon slot="start" icon={addOutline} /> Nuevo{" "}
                    </IonButton>
                    <svg
                      id="happy-face"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon ion-margin"
                      viewBox="0 0 512 512"
                    >
                      <title>Happy</title>
                      <circle cx="184" cy="232" r="24" />
                      <path d="M256.05 384c-45.42 0-83.62-29.53-95.71-69.83a8 8 0 017.82-10.17h175.69a8 8 0 017.82 10.17c-11.99 40.3-50.2 69.83-95.62 69.83z" />
                      <circle cx="328" cy="232" r="24" />
                      <circle
                        cx="256"
                        cy="256"
                        r="208"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      />
                    </svg>
                    <IonLabel color="dark">
                      <IonText>
                        <h1>
                          <IonIcon slot="start" icon={bagCheckOutline} /> Pedido
                          generado con éxito!!!
                        </h1>
                      </IonText>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <>
                <IonGrid>
                  <IonRow>
                    <IonCol size="4" className="max-height">
                      <IonLabel className="ion-text-center">
                        <h1>Carrito</h1>
                      </IonLabel>
                      <IonList className="cart">
                        {lineItem.map((li: LineItem, index) => {
                          let prod = products.find(
                            (el) => el.id == li.product_id
                          );
                          let urlImage =
                            prod?.images[0] != undefined
                              ? imageUrl("" + prod?.images[0].src)
                              : "assets/images/toppet-black-low.jpg";
                          return (
                            <IonItem key={li.product_id} title={li.name}>
                              <IonThumbnail slot="start">
                                <img src={urlImage} />
                              </IonThumbnail>
                              <IonLabel title={li.name}>{li.name}</IonLabel>
                              <IonBadge color="dark" slot="end">
                                {li.quantity}
                              </IonBadge>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    </IonCol>
                    <IonCol size="8">
                      <IonRow>
                        <IonCol size="6">
                          <IonCard color="success">
                            <IonCardContent>
                              <IonCardSubtitle>Total a cobrar:</IonCardSubtitle>
                              <IonRow>
                                <IonCol>
                                  <div className="ch-flex ion-justify-content-between">
                                    <IonText>
                                      <small>Subtotal: </small>
                                    </IonText>
                                    <IonText>
                                      <small>
                                        + {getFormatter(getPriceIVA(subtotal))}
                                      </small>
                                    </IonText>
                                  </div>
                                  <div className="ch-flex ion-justify-content-between">
                                    <IonText>
                                      <small>Envio: </small>
                                    </IonText>
                                    <IonText>
                                      <small>
                                        + {getFormatter(+shippingCost)}
                                      </small>
                                    </IonText>
                                  </div>
                                  <div className="ch-flex ion-justify-content-between">
                                    <IonText>
                                      <small>Descuento: </small>
                                    </IonText>
                                    <IonText>
                                      <small>
                                        -{" "}
                                        {getFormatter(
                                          +getDiscount(
                                            discountType,
                                            discount,
                                            subtotal
                                          )
                                        )}
                                      </small>
                                    </IonText>
                                  </div>
                                </IonCol>
                                <IonCol className="ch-flex ion-justify-content-center ion-align-items-center">
                                  <IonText>
                                    <h1 className="ion-text-center">
                                      {" "}
                                      {getFormatter(
                                        getTotal(subtotal, +shippingCost) -
                                          +getDiscount(
                                            discountType,
                                            discount,
                                            subtotal
                                          )
                                      )}
                                    </h1>
                                  </IonText>
                                </IonCol>
                              </IonRow>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        <IonCol size="6">
                          <IonCard>
                            <IonCardContent>
                              <IonCardSubtitle>Tipo DTE</IonCardSubtitle>
                              <IonRow>
                                <IonCol size="6">
                                  <IonChip
                                    onClick={() => setBoleta(true)}
                                    color={boleta ? "primary" : "medium"}
                                  >
                                    <IonIcon icon={readerOutline} />
                                    &nbsp;<IonLabel> Boleta</IonLabel>{" "}
                                  </IonChip>
                                </IonCol>
                                <IonCol size="6">
                                  <IonChip
                                    onClick={() => setBoleta(false)}
                                    color={!boleta ? "primary" : "medium"}
                                  >
                                    <IonIcon icon={readerOutline} />
                                    &nbsp;<IonLabel> Factura</IonLabel>{" "}
                                  </IonChip>
                                </IonCol>
                              </IonRow>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12">
                          <p>Medio de pago</p>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="3">
                          <IonChip
                            onClick={() => setPayMethod("cash")}
                            color={payMethod == "cash" ? "primary" : "medium"}
                          >
                            <IonIcon icon={cashOutline} />
                            &nbsp;<IonLabel> Efectivo</IonLabel>{" "}
                          </IonChip>
                        </IonCol>
                        <IonCol size="3">
                          <IonChip
                            onClick={() => setPayMethod("credit")}
                            color={payMethod == "credit" ? "primary" : "medium"}
                          >
                            <IonIcon icon={cardOutline} />
                            &nbsp;<IonLabel> Crédito</IonLabel>{" "}
                          </IonChip>
                        </IonCol>
                        <IonCol size="3">
                          <IonChip
                            onClick={() => setPayMethod("debit")}
                            color={payMethod == "debit" ? "primary" : "medium"}
                          >
                            <IonIcon icon={cardOutline} />
                            &nbsp;<IonLabel> Débito</IonLabel>{" "}
                          </IonChip>
                        </IonCol>
                        <IonCol size="3">
                          <IonChip
                            onClick={() => setPayMethod("bacs")}
                            color={payMethod == "bacs" ? "primary" : "medium"}
                          >
                            <IonIcon icon={swapVerticalOutline} />
                            &nbsp;<IonLabel> Transferencia</IonLabel>{" "}
                          </IonChip>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonCard className="return-cash" color="light">
                            <IonCardContent>
                              <IonCardSubtitle>
                                Vuelto de efectivo
                              </IonCardSubtitle>
                              <IonToolbar color="light">
                                <IonItem
                                  slot="start"
                                  lines="none"
                                  disabled={payMethod == "cash" ? false : true}
                                >
                                  <IonLabel>Recibido: $ </IonLabel>
                                  <IonInput
                                    ref={cashInput}
                                    onIonChange={returnCash}
                                    type="number"
                                    disabled={
                                      payMethod == "cash" ? false : true
                                    }
                                  >
                                    {" "}
                                  </IonInput>
                                </IonItem>
                                <IonItem
                                  class="value-return"
                                  slot="end"
                                  lines="none"
                                  color={
                                    cash -
                                      (getTotal(subtotal, +shippingCost) -
                                        +getDiscount(
                                          discountType,
                                          discount,
                                          subtotal
                                        )) <
                                    0
                                      ? "danger"
                                      : "success"
                                  }
                                  disabled={payMethod == "cash" ? false : true}
                                >
                                  <IonLabel>
                                    {getFormatter(
                                      cash -
                                        (getTotal(subtotal, +shippingCost) -
                                          +getDiscount(
                                            discountType,
                                            discount,
                                            subtotal
                                          ))
                                    )}
                                  </IonLabel>
                                </IonItem>
                              </IonToolbar>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        <IonCol size="4"></IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonFooter className="ion-no-border">
                  <IonToolbar className="ion-padding-end ion-padding-start">
                    <IonItem lines="none">
                      {charging ? (
                        <>
                          <IonSpinner slot="start" name="lines" color="dark" />
                          <IonLabel slot="start">
                            {" "}
                            Procesando la orden...
                          </IonLabel>
                        </>
                      ) : (
                        <IonLabel slot="start"> {errorInSend}</IonLabel>
                      )}
                    </IonItem>
                    <IonButton
                      color="light"
                      slot="end"
                      onClick={() => {
                        setIsSearch(false);
                        setShowModal(false);
                      }}
                      disabled={completeOrder || charging}
                    >
                      <IonIcon slot="start" icon={closeOutline} /> Cerrar{" "}
                    </IonButton>
                    <IonButton
                      color="warning"
                      slot="end"
                      onClick={() => {
                        sendOrder();
                        setIsSearch(false);
                      }}
                      disabled={completeOrder || charging}
                    >
                      <IonIcon slot="start" icon={checkmarkOutline} />
                      Completar Orden{" "}
                    </IonButton>
                  </IonToolbar>
                </IonFooter>
              </>
            )}
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={showModalCustomer}
          className="modal-customer"
          backdropDismiss={false}
        >
          <IonHeader>
            <IonToolbar color="dark">
              <IonTitle slot="start">Cliente</IonTitle>
              {charging ? (
                <IonSpinner slot="start" name="dots" color="warning" />
              ) : (
                ""
              )}
              <IonButton
                className="ion-padding-end"
                slot="end"
                color="light"
                onClick={() => {
                  setShowModalCustomer(false);
                  setIsSearch(false);
                }}
              >
                <IonIcon color="dark" icon={closeOutline} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonSearchbar
              value={searchTextCustomer}
              debounce={1500}
              onIonChange={(e) => setSearchTextCustomer(e.detail.value!)}
              placeholder="3 caracteres como mínimo"
            ></IonSearchbar>
            <IonText className="ion-text-center ion-padding-start">
              <small>
                Los clientes se buscan por el correo electrónico. Debe
                introducir 3 caracteres como mínimo.
              </small>
            </IonText>
            <IonList>
              {customerList.length == 0 ? (
                <IonItem>
                  <IonLabel>No hay clientes para mostrar</IonLabel>
                </IonItem>
              ) : (
                ""
              )}
              {customerList.map((c: Customer, index) => {
                return (
                  <IonItem
                    key={c.id}
                    button
                    onClick={() => {
                      setCustomer(c);
                      setShowModalCustomer(false);
                      setIsSearch(false);
                    }}
                  >
                    <IonLabel>{c.first_name + " " + c.last_name}</IonLabel>
                    <IonText>
                      <small>{c.email}</small>
                    </IonText>
                  </IonItem>
                );
              })}
            </IonList>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={showModalCustomerAdd}
          className="modal-add-customer"
          backdropDismiss={false}
        >
          <IonHeader>
            <IonToolbar color="dark">
              <IonTitle slot="start">Nuevo Cliente</IonTitle>
              {charging ? (
                <IonSpinner slot="start" name="dots" color="warning" />
              ) : (
                ""
              )}
              <IonButton
                className="ion-padding-end"
                slot="end"
                color="light"
                onClick={() => {
                  setShowModalCustomerAdd(false);
                  setIsSearch(false);
                }}
              >
                <IonIcon color="dark" icon={closeOutline} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <AddClient
            setCustomer={setCustomer}
            charging={charging}
            setCharging={setCharging}
            setShowModalCustomerAdd={setShowModalCustomerAdd}
            setIsSearch={setIsSearch}
            searchTextFocus={searchTextFocus}
          />
        </IonModal>
        <IonModal
          isOpen={showModalVariation}
          className="modal-variations"
          backdropDismiss={false}
        >
          <IonHeader>
            <IonToolbar color="dark">
              <IonTitle slot="start">{variations?.product.name}</IonTitle>
              {charging ? (
                <IonSpinner slot="start" name="dots" color="warning" />
              ) : (
                ""
              )}
              <IonButton
                className="ion-padding-end"
                slot="end"
                color="light"
                onClick={() => {
                  setShowModalVariation(false);
                  setIsSearch(false);
                }}
              >
                <IonIcon color="dark" icon={closeOutline} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              {variations != null ? (
                variations?.variations.map((v: Variation) => {
                  let urlImage =
                    v.image != undefined
                      ? imageUrl("" + v.image.src)
                      : "assets/images/toppet-black-low.jpg";
                  variationsLog[v.id] = v;
                  return (
                    <IonItem
                      key={v.id}
                      button
                      onClick={() => {
                        addProductToCart(variations.product, v);
                        setShowModalVariation(false);
                        setIsSearch(false);
                      }}
                    >
                      <IonThumbnail slot="start">
                        <img src={urlImage} />
                      </IonThumbnail>
                      <IonLabel>
                        {v.attributes.map((a: Attributes) => {
                          return (
                            <IonText key={a.id}>
                              {a.name + ": " + a.option}
                            </IonText>
                          );
                        })}
                      </IonLabel>
                      <IonText slot="end">
                        {getFormatter(+getPriceIVA(+v.price))}
                      </IonText>
                      <IonText slot="end">
                        <IonBadge color="dark">{v.stock_quantity}</IonBadge>
                      </IonText>
                    </IonItem>
                  );
                })
              ) : (
                <IonItem>
                  <IonSpinner slot="start" name="lines" color="warning" />
                  <IonLabel>Cargando</IonLabel>
                </IonItem>
              )}
            </IonList>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Pos;
