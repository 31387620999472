import React, { ReactNode, useDebugValue, useState } from "react";
import AppContext, { AppContextType } from "./AppContext";
import { User } from "../../modals/modals";
import { useHistory } from "react-router-dom";
import { removeItem, setObject } from "../../hooks/storage";
import { useIonToast } from "@ionic/react";
import { errorReturnMessage } from "../../hooks/functions";

interface Props {
  children: ReactNode;
}
const AppContextProvider = ({ children }: Props) => {
  const [userAuth, setUserAuth] = useState<User | null>(null);
  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const saveUserAuth = (user: User | null) => {
    if (user) {
      setObject("userAuthCashay", user);
      setUserAuth(user);
    } else {
      removeItem("userAuthCashay");
      setUserAuth(null);
    }
  };
  const openPresent = (
    text: string,
    error: boolean,
    color: string,
    href: string,
    time: number
  ) => {
    let errorRes = [];
    errorRes = errorReturnMessage(text);
    //let pColor= error?"danger":"success";
    present({
      buttons:
        href != ""
          ? [
              {
                side: "end",
                text: "Ver los pedidos",
                handler: () => {
                  window.open(
                    "https://toppet.cl/store-manager/orderslist/",
                    "_blank"
                  );
                },
              },
              { side: "end", icon: "close-circle", handler: () => dismiss() },
            ]
          : [{ side: "end", icon: "close-circle", handler: () => dismiss() }],
      message: errorRes[1],
      duration: time,
      color: color,
      header: error ? errorRes[0] : "Información",
      position: "top",
    });
  };
  /*const saveToken = (token: string) => {
        setToken(token);
    };*/
  const appContext: AppContextType = {
    userAuth,
    saveUserAuth,
    openPresent,
  };
  /*const getWelcomeApp=async ()=>{
        const { value} = await Storage.get({ key: 'name' }).then(
            value?setWelcomeApp("v1"):setWelcomeApp(null);
        );
    }*/
  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};
export default AppContextProvider;
