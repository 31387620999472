import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonPopover,
  IonRow,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./OrderReport.css";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import AppContext from "../context/App/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import { Order, LineItem, ShippingLine } from "../modals/modals";
import {
  editOrderPayment,
  getFormatter,
  getNotes,
  payMethodTitle,
} from "../hooks/functions";
import OrderStatus from "../components/reports/OrderStatus";
import { format, parseISO } from "date-fns";
import {
  arrowBack,
  cardOutline,
  carOutline,
  cashOutline,
  close,
  pencil,
  swapVerticalOutline,
} from "ionicons/icons";
import { sign } from "crypto";
import { MetaDatum } from "../components/WooTypes";

const OrderReport: React.FC = () => {
  const { userAuth, openPresent } = useContext(AppContext);
  const [cashbackPay, setCashbackPay] = useState(0);
  const history = useHistory();
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [payMethod, setPayMethod] = useState("cash");
  const [charging, setCharging] = useState<boolean>(false);
  const [orderNotes, setOrderNotes] = useState<any[]>([]);
  const [isOrderModified, setIsOrderModified] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };
  if (!userAuth) {
    history.replace("/login");
  }
  let authToken = "Bearer " + userAuth?.store_token;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/order") {
      setOrder(location.state as Order);
    }
    setOrderNotes([]);
    getOrderNotes();
    //return setOrderNotes;
  }, [location.pathname]);
  useEffect(() => {
    if (order) {
      setCashbackPay(0);
      let totalTemp: number = 0;
      order?.fee_lines.map((fl: any) => {
        totalTemp += Math.abs(fl.total);
      });
      setCashbackPay(totalTemp);
      setPayMethod(order.payment_method);
    }
  }, [order]);
  //Change payment method -----------------------------------------------------------------------------
  const changePayMethod = () => {
    setCharging(true);
    if (order)
      editOrderPayment(authToken, order.id, payMethod)
        .then((res) => {
          if (order) {
            setOrder({
              ...order,
              payment_method: payMethod,
              payment_method_title: payMethodTitle(payMethod)
            });
            setIsOrderModified(true);
          }
          setPopoverOpen(false);
        })
        .catch((error) => {
          openPresent(error.toString(), true, "danger", "", 3000);
        })
        .finally(() => {
          setCharging(false);
        });
  };
  //change order status -------------------------------------------------------------------------------
  const changeOrderStateStatus = (newStatus: string) => {
    if (order) {
      setOrder({
        ...order,
        status: newStatus,
      });
      setIsOrderModified(true);
    }
  };
  //Load order notes ----------------------------------------------------------------------------------
  const getOrderNotes = async () => {
    setCharging(true);
    let tempNotes = orderNotes;
    if (order)
      await getNotes(authToken, order.id).then((res) => {
        tempNotes = res.data;
      });
    setOrderNotes(tempNotes);
    setCharging(false);
  };
  const goToReports = () => {
    let stateVar: Order | null = null;
    if (isOrderModified) {
      stateVar = order;
    }
    history.push({
      pathname: "/informes",
      state: stateVar,
    });
  };
  return (
    <IonPage>
      <Header />
      <IonContent id="order" fullscreen>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => goToReports()}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonItem className="ion-text-left">
            <IonTitle>
              <IonText>Pedido {order?.id} </IonText>
              <IonText>
                <OrderStatus
                  status={order ? order.status : ""}
                  orderID={order ? order.id : 0}
                  changeOrderStateStatus={changeOrderStateStatus}
                />
              </IonText>
              <IonText className="ion-margin">
                <small>
                  (Creado el{" "}
                  {order
                    ? format(parseISO(order.date_created + ""), "dd/MM/yyyy")
                    : ""}
                  )
                </small>
              </IonText>
            </IonTitle>
            <IonLabel slot="end">
              Cajero:{" "}
              {
                order?.meta_data.find((ind) => ind.key == "_lo_cajero_name")
                  ?.value
              }
            </IonLabel>
          </IonItem>
        </IonToolbar>
        <IonGrid className="ion-margin order-report">
          <IonRow>
            <IonCol size="4">
              <IonRow>
                <IonCol size="auto">
                  <div>
                    Pago a través de{" "}
                    <strong>{order?.payment_method_title}</strong>{" "}
                  </div>
                  <div>
                    con fecha{" "}
                    {order
                      ? format(
                          parseISO(order?.date_created + ""),
                          "dd-MM-yyyy HH:mm"
                        )
                      : ""}
                  </div>
                </IonCol>
                <IonCol>
                  {order?.created_via == "rest-api" &&
                    userAuth &&
                    (userAuth.roles.indexOf("administrator") > -1 ||
                      userAuth.roles.indexOf("shop_manager") > -1) && (
                      <>
                        <IonButton
                          id="edit-payment"
                          size="small"
                          color="dark"
                          onClick={openPopover}
                          disabled={charging}
                        >
                          <IonIcon size="normal" icon={pencil} />
                        </IonButton>
                        <IonPopover
                          size="auto"
                          ref={popover}
                          isOpen={popoverOpen}
                          onDidDismiss={() => setPopoverOpen(false)}
                          backdropDismiss={false}
                        >
                          <IonContent class="ion-padding">
                            {charging && (
                              <IonItem>
                                <IonSpinner name="lines" slot="start" />
                                <IonLabel> Cambiando...</IonLabel>
                              </IonItem>
                            )}
                            <p>Medio de pago</p>
                            <div>
                              <IonChip
                                onClick={() => setPayMethod("cash")}
                                color={
                                  payMethod == "cash" ? "primary" : "medium"
                                }
                              >
                                <IonIcon icon={cashOutline} />
                                &nbsp;<IonLabel> Efectivo</IonLabel>{" "}
                              </IonChip>
                            </div>
                            <div>
                              <IonChip
                                onClick={() => setPayMethod("credit")}
                                color={
                                  payMethod == "credit" ? "primary" : "medium"
                                }
                              >
                                <IonIcon icon={cardOutline} />
                                &nbsp;<IonLabel> Crédito</IonLabel>{" "}
                              </IonChip>
                            </div>
                            <div>
                              <IonChip
                                onClick={() => setPayMethod("debit")}
                                color={
                                  payMethod == "debit" ? "primary" : "medium"
                                }
                              >
                                <IonIcon icon={cardOutline} />
                                &nbsp;<IonLabel> Débito</IonLabel>{" "}
                              </IonChip>
                            </div>
                            <div>
                              <IonChip
                                onClick={() => setPayMethod("bacs")}
                                color={
                                  payMethod == "bacs" ? "primary" : "medium"
                                }
                              >
                                <IonIcon icon={swapVerticalOutline} />
                                &nbsp;<IonLabel> Transferencia</IonLabel>{" "}
                              </IonChip>
                            </div>
                            <IonToolbar className="ion-no padding">
                              <IonButton
                                onClick={() => {
                                  setPopoverOpen(false);
                                  if (order?.payment_method != payMethod) {
                                    setPayMethod(order?.payment_method);
                                  }
                                }}
                                color="light"
                                size="small"
                                disabled={charging}
                              >
                                <IonIcon size="normal" icon={close} />
                              </IonButton>
                              <IonButton
                                onClick={() => changePayMethod()}
                                color="warning"
                                size="small"
                                disabled={
                                  order?.payment_method == payMethod || charging
                                    ? true
                                    : false
                                }
                              >
                                <IonIcon size="normal" icon={pencil} /> Cambiar
                              </IonButton>
                            </IonToolbar>
                          </IonContent>
                        </IonPopover>
                      </>
                    )}
                </IonCol>{" "}
              </IonRow>
              <p>
                Cliente:{" "}
                <strong>
                  {order?.billing.first_name
                    ? order?.billing.first_name + " " + order?.billing.last_name
                    : "Invitado"}
                </strong>
              </p>
              <div>
                Creado vía:{" "}
                <strong>
                  {order?.created_via == "rest-api" ? "POS" : "WEB"}
                </strong>
              </div>
              {order?.shipping_lines.length == 0 ? (
                ""
              ) : (
                <>
                  <IonItemDivider></IonItemDivider>

                  <IonLabel>
                    <h2>
                      Método de envío{""}
                      <IonIcon color="dark" size="small" icon={carOutline} />
                    </h2>
                    <p>{order?.shipping_lines[0].method_title}</p>
                  </IonLabel>
                </>
              )}
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Facturación</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  {order?.billing.first_name ? (
                    <>
                      <div>
                        {order?.billing.first_name +
                          " " +
                          order?.billing.last_name}
                      </div>
                      <div>{order?.billing.address_1}</div>
                      <div>{order?.billing.address_2}</div>
                      <div>
                        {order?.billing.country + " - " + order?.billing.state}
                      </div>
                      <div>{order?.billing.city}</div>
                      <div>{order?.billing.postcode}</div>
                      <div>
                        <strong>Email:</strong> {order?.billing.email}
                      </div>
                      <div>
                        <strong>Teléfono:</strong> {order?.billing.phone}
                      </div>
                      <div>
                        <strong>RUT:</strong>{" "}
                        {
                          order?.meta_data.find(
                            (ind) => ind.key == "billing_lo_rut"
                          )?.value
                        }
                      </div>
                    </>
                  ) : (
                    <>"No hay detalles de facturación"</>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Envío</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  {order?.shipping.first_name ? (
                    <>
                      <div>
                        {order?.shipping.first_name +
                          " " +
                          order?.shipping.last_name}
                      </div>
                      <div>{order?.shipping.address_1}</div>
                      <div>{order?.shipping.address_2}</div>
                      <div>
                        {order?.shipping.country +
                          " - " +
                          order?.shipping.state}
                      </div>
                      <div>{order?.shipping.city}</div>
                      <div>{order?.shipping.postcode}</div>
                      <div>
                        <strong>Email:</strong> {order?.shipping.email}
                      </div>
                      <div>
                        <strong>Teléfono:</strong> {order?.shipping.phone}
                      </div>
                    </>
                  ) : (
                    <>"No hay detalles de envío"</>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardContent className="ion-no-padding">
                  <table>
                    <thead>
                      <tr>
                        <th>Artículo</th>
                        <th>Coste</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th>IVA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.line_items.map((li: LineItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <IonItem lines="none">
                                <IonThumbnail className="ion-margin-end">
                                  <img
                                    alt={li.name}
                                    src={
                                      li.image_url
                                        ? li.image_url
                                        : "assets/images/toppet-black-low.jpg"
                                    }
                                  />
                                </IonThumbnail>
                                <IonLabel>
                                  <h3>{li.name}</h3>
                                  <p>
                                    <small>SKU: {li.sku ? li.sku : "-"}</small>
                                  </p>
                                </IonLabel>
                              </IonItem>
                            </td>
                            <td>
                              {getFormatter(
                                +(li.price ? +li.price : 0).toFixed(0)
                              )}
                            </td>
                            <td>
                              <small>x</small> {li.quantity}
                            </td>
                            <td>
                              {getFormatter(
                                +(li.total ? +li.total : 0).toFixed(0)
                              )}
                            </td>
                            <td>
                              {getFormatter(
                                +(li.total_tax ? +li.total_tax : 0).toFixed(0)
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      {order?.fee_lines.map((fl: any, index) => {
                        return (
                          <tr key={index}>
                            <td
                              colSpan={3}
                              className="ion-text-start ion-padding-left"
                            >
                              <IonItem lines="none">
                                <IonThumbnail className="ion-margin-end">
                                  <img
                                    alt="Pago"
                                    src="assets/images/wallet.png"
                                  />
                                </IonThumbnail>
                                <IonLabel>
                                  <p>
                                    <small>Descuento</small>
                                  </p>
                                  <h3>{fl.name}</h3>
                                </IonLabel>
                              </IonItem>
                            </td>
                            <td>
                              {getFormatter(
                                +(fl.amount ? +fl.total : 0).toFixed(0)
                              )}
                            </td>
                            <td>
                              {getFormatter(
                                +(fl.total_tax ? +fl.total_tax : 0).toFixed(0)
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      {order?.shipping_lines.map((sl: ShippingLine, index) => {
                        return (
                          <tr key={index}>
                            <td
                              colSpan={3}
                              className="ion-text-start ion-padding-left"
                            >
                              <IonItem lines="none">
                                <IonThumbnail className="ion-margin-end">
                                  <img
                                    alt="Envío"
                                    src="assets/images/envio.jpg"
                                  />
                                </IonThumbnail>
                                <IonLabel>
                                  <p>
                                    <small>Método de envío</small>
                                  </p>
                                  <h3>{sl.method_title}</h3>
                                </IonLabel>
                              </IonItem>
                            </td>
                            <td>
                              {getFormatter(
                                +(sl.total ? +sl.total : 0).toFixed(0)
                              )}
                            </td>
                            <td>
                              {getFormatter(
                                +(sl.total_tax ? +sl.total_tax : 0).toFixed(0)
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>&nbsp;</IonCol>
            <IonCol size="auto">
              <table>
                <tbody>
                  <tr className="no-border">
                    <td className="ion-text-right">
                      Subtotal de artículos: &nbsp;&nbsp;&nbsp;
                    </td>
                    <td className="ion-text-right">
                      <strong>
                        {order
                          ? getFormatter(
                              +(
                                +order.total -
                                +order.total_tax -
                                +order.shipping_total +
                                +cashbackPay +
                                (order.coupon_lines.length > 0
                                  ? +order.coupon_lines[0].discount
                                  : 0)
                              ).toFixed(0)
                            )
                          : 0}
                      </strong>
                    </td>
                  </tr>
                  {order && order?.coupon_lines.length > 0 && (
                    <tr className="no-border">
                      <td className="ion-text-right">
                        Descuento: &nbsp;&nbsp;&nbsp;
                      </td>
                      <td className="ion-text-right">
                        <strong>
                          -{" "}
                          {getFormatter(
                            +(+order.coupon_lines[0].discount).toFixed(0)
                          )}
                        </strong>
                      </td>
                    </tr>
                  )}
                  <tr className="no-border">
                    <td className="ion-text-right">
                      Envío: &nbsp;&nbsp;&nbsp;
                    </td>
                    <td className="ion-text-right">
                      <strong>
                        {getFormatter(
                          +(
                            order?.shipping_total ? +order.shipping_total : 0
                          ).toFixed(0)
                        )}
                      </strong>
                    </td>
                  </tr>
                  <tr className="no-border">
                    <td className="ion-text-right">IVA: &nbsp;&nbsp;&nbsp;</td>
                    <td className="ion-text-right">
                      <strong>
                        {getFormatter(
                          +(order?.total_tax ? +order.total_tax : 0).toFixed(0)
                        )}
                      </strong>
                    </td>
                  </tr>
                  {cashbackPay > 0 && (
                    <tr className="no-border">
                      <td className="ion-text-right">
                        Pago con Cashback: &nbsp;&nbsp;&nbsp;
                      </td>
                      <td className="ion-text-right">
                        <strong>
                          - {getFormatter(+cashbackPay.toFixed(0))}
                        </strong>
                      </td>
                    </tr>
                  )}
                  <tr className="no-border">
                    <td className="ion-text-right">
                      Total del pedido: &nbsp;&nbsp;&nbsp;
                    </td>
                    <td className="ion-text-right">
                      <strong>
                        {getFormatter(
                          +(order?.total ? +order.total : 0).toFixed(0)
                        )}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Notas del Pedido</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  {charging && (
                    <div className="ion-text-center">
                      <IonSpinner></IonSpinner>
                    </div>
                  )}
                  {orderNotes.map((note: any, index) => {
                    let doc = new DOMParser().parseFromString(
                      note.note,
                      "text/html"
                    );
                    return (
                      <p key={index}>
                        {format(
                          parseISO(note.date_created + ""),
                          "dd-MM-yyyy HH:mm"
                        ) +
                          " " +
                          doc.body.textContent || ""}
                      </p>
                    );
                  })}
                  {!charging && orderNotes.length == 0 && (
                    <div className="ion-text-center">
                      Este pedido no tiene notas.
                    </div>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default OrderReport;
