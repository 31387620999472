import React, { useContext, useEffect } from 'react';
import { IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonMenuToggle, IonToolbar } from '@ionic/react';
import { menu, refreshOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import AppContext from './../context/App/AppContext';
import './Header.css';
import printJS from 'print-js';

const Header: React.FC = () => {
    const { userAuth } = useContext(AppContext);
    const history = useHistory();
    if (!userAuth) {
        history.replace("/login");
    }
    //let authToken = "Bearer " + userAuth?.token;
    /*useEffect(() => {
        const interval = setInterval(() => {
            //checkOrders();
            console.log("1");
        }, 1000);
        return () => clearInterval(interval);
      }, []);*/
    return (
        <IonHeader>
            <IonToolbar color="dark">
                <IonButtons slot="start">
                    <IonMenuToggle>
                        <IonButton>
                            <IonIcon slot="icon-only" icon={menu}></IonIcon>
                        </IonButton>
                    </IonMenuToggle>
                    <IonItem color="dark" className="" lines="none">
                        <IonLabel>&nbsp; {userAuth?.store_name}</IonLabel>
                    </IonItem>
                </IonButtons>                
                <IonButton slot="end" color="warning" href="https://pos.toppet.cl"><IonIcon icon={refreshOutline}></IonIcon><IonLabel>Recargar</IonLabel></IonButton>
                <IonButtons slot="end">                    
                    <IonItem className="ch-logo-header-item" href="https://toppet.cl" target="_blank" color="dark" lines="none">
                        <IonImg className="ch-logo-header" src="assets/images/logo-white-low.png"></IonImg>
                    </IonItem>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;