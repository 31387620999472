import {
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { close, pencil } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/App/AppContext";
import { OrderStatusModel } from "../../modals/modals";
import { editOrderStatus, orderStatusFunc } from "../../hooks/functions";

interface ContainerProps {
  status: string;
  orderID: number;
  modOrders?: (orderId: number, field: string, value: string) => void;
  changeOrderStateStatus?: (newStatus: string) => void;
}

const OrderStatus: React.FC<ContainerProps> = ({
  status,
  orderID,
  modOrders,
  changeOrderStateStatus,
}) => {
  const { userAuth, openPresent } = useContext(AppContext);
  let authToken = "Bearer " + userAuth?.store_token;
  const [os, setOs] = useState<OrderStatusModel>({
    text: "",
    color: "",
    icon: "",
  });
  const [isOpenMS, setIsOpenMS] = useState(false);

  const [charging, setCharging] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const changeStatus = () => {
    setCharging(true);
    editOrderStatus(authToken, orderID, newStatus)
      .then(() => {
        status = newStatus;
        console.log(status);
      })
      .catch()
      .finally(() => {
        setCharging(false);
        if (modOrders) {
          modOrders(orderID, "status", newStatus);
        }
        if (changeOrderStateStatus) {
          changeOrderStateStatus(newStatus);
        }
      });
  };
  useEffect(() => {
    setOs(orderStatusFunc(status));
    setNewStatus(status);
    return setIsOpenMS(false);
  }, [status]);

  return (
    <>
      {userAuth &&
      (userAuth.roles.indexOf("administrator") > -1 ||
        userAuth.roles.indexOf("shop_manager") > -1) ? (
        <>
          <IonButton
            size="small"
            onClick={() => setIsOpenMS(true)}
            disabled={charging}
            color={os.color}
          >
            <IonIcon size="small" icon={pencil} /> &nbsp;{" " + os.text}
          </IonButton>
          <IonModal
            isOpen={isOpenMS}
            onDidDismiss={() => setIsOpenMS(false)}
            backdropDismiss={false}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>Estado del pedido</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsOpenMS(false)}>
                    Cerrar
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              {charging && (
                <IonItem>
                  <IonSpinner name="lines" slot="start" />
                  <IonLabel> Cambiando...</IonLabel>
                </IonItem>
              )}

              <IonChip
                onClick={() => setNewStatus("completed")}
                color={newStatus == "completed" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("completed").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("processing")}
                color={newStatus == "processing" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("processing").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("pending")}
                color={newStatus == "pending" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("pending").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("on-hold")}
                color={newStatus == "on-hold" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("on-hold").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("cancelled")}
                color={newStatus == "cancelled" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("cancelled").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("failed")}
                color={newStatus == "failed" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("failed").text}</IonLabel>{" "}
              </IonChip>
              <IonChip
                onClick={() => setNewStatus("refunded")}
                color={newStatus == "refunded" ? "primary" : "medium"}
              >
                <IonLabel> {orderStatusFunc("refunded").text}</IonLabel>{" "}
              </IonChip>
            </IonContent>
            <IonFooter>
              <IonToolbar className="ion-padding-end ion-text-end">
                <IonButton
                  onClick={() => {
                    setIsOpenMS(false);
                    setIsOpenMS(false);
                    setIsOpenMS(false);
                    setIsOpenMS(false);
                    console.log(isOpenMS);

                    if (status != newStatus) {
                      setNewStatus(status);
                    }
                  }}
                  color="light"
                  size="small"
                  disabled={charging}
                >
                  <IonIcon size="normal" icon={close} />
                </IonButton>
                <IonButton
                  onClick={() => changeStatus()}
                  color="warning"
                  size="small"
                  disabled={status == newStatus || charging}
                >
                  <IonIcon size="normal" icon={pencil} /> Cambiar
                </IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
        </>
      ) : (
        <IonBadge color={os.color}>{os.text}</IonBadge>
      )}
    </>
  );
};

export default OrderStatus;
