import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { addCircle, barcode, search } from "ionicons/icons";
import "./ProductItem.css";
import { Product } from "../../modals/modals";
import { getFormatter, getPriceIVA, imageUrl } from "../../hooks/functions";
import { useEffect, useState } from "react";

const SearchText = (props: any) => {
  const {
    isSearch,
    setIsSearch,
    products,
    setSearchTextFilter,
    charging,
    setCharging,
    addProductToCart,
  } = props;
  const [searchText, setSearchText] = useState("");
  const changeSearch = (chSearch: boolean) => {
    if (!chSearch) {
      setSearchText("");
    }
    document.querySelector<HTMLInputElement>("#pos-search input")?.focus();
    setIsSearch(chSearch);
  };
  const searchTextChange = () => {
    if (searchText != "") {
      let p: Product | undefined = products.find(
        (el: Product) => (el.sku + "").split("-", 1)[0] == searchText
      );

      if (p != undefined) {
        addProductToCart(p, null);
      }
      setSearchText("");
    }
  };
  useEffect(() => {
    if (!isSearch) {
      searchTextChange();
    }
  }, [searchText]);
  const ifEnterKey = (e: string) => {
    if (e === "Enter") {
      setSearchTextFilter(searchText);
    }
  };
  return (
    <IonToolbar className="search-bar">
      <IonSearchbar
        id="pos-search"
        slot="start"
        value={searchText}
        onIonChange={(ev) => {
          const target = ev.target as HTMLIonSearchbarElement;
          if (target) {
            setSearchText(target.value!);
          }
        }}
        onKeyUp={(e) => ifEnterKey(e.key)}
        showClearButton="always"
        placeholder="Buscar"
        onClick={() => changeSearch(true)}
      ></IonSearchbar>
      <IonButton slot="start" onClick={() => setSearchTextFilter(searchText)}>
        Buscar
      </IonButton>
      <IonButtons slot="end" className="ion-margin-start">
        <IonItem
          lines="none"
          button
          color={isSearch ? "dark" : "light"}
          onClick={() => changeSearch(true)}
        >
          <IonIcon size="normal" icon={search} />
        </IonItem>
        <IonItem
          lines="none"
          button
          color={!isSearch ? "dark" : "light"}
          onClick={() => changeSearch(false)}
        >
          <IonIcon size="normal" icon={barcode} />
        </IonItem>
      </IonButtons>
    </IonToolbar>
  );
};

export default SearchText;
