import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonPopover,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonIcon,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./Reports.css";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import AppContext from "../context/App/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import { Order, ReportSales } from "../modals/modals";
import { getFormatter, payMethodTitle } from "../hooks/functions";
import OrderStatus from "../components/reports/OrderStatus";
import { format, parseISO } from "date-fns";
import printJS from "print-js";
import { close, printOutline } from "ionicons/icons";
import { modOrderList } from "../hooks/Reports/Controllers";

const Reports: React.FC = () => {
  const { userAuth, openPresent } = useContext(AppContext);
  const history = useHistory();
  if (!userAuth) {
    history.replace("/login");
  }
  //Modificar pedido----------------------------------------------------------
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/informes") {
      const orderToMod = location.state as Order;
      if (orderToMod) {
        modOrderList(orders, orderToMod);
      }
    }
  }, [location.pathname]);
  //--------------------------------------------------------------------------
  let authToken = "Bearer " + userAuth?.store_token;
  const [charging, setCharging] = useState<boolean>(true);
  const [firstExe, setFirstExe] = useState<boolean>(true);
  const [orderPage, setOrderPage] = useState(1);
  //const [ops, setOps] = useState<number>(1);
  /*const todayDay:Date=new Date();
console.log(todayDay+"");*/
  const modal = useRef<HTMLIonModalElement>(null);
  function dismiss() {
    modal.current?.dismiss();
  }
  let now: Date = new Date();
  //let today:Date= new Date('2022-2-26T00:00:00');
  let today =
    now.getFullYear() +
    "-" +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + now.getDate()).slice(-2);
  const [day, setDay] = useState(today);
  const initState = {
    total_sales: 0,
    average_sales: 0,
    net_sales: 0,
    total_customers: 0,
    total_discount: 0,
    total_items: 0,
    total_orders: 0,
    total_orders_complete: 0,
    total_refunds: 0,
    total_shipping: 0,
    total_tax: 0,
    payment: {},
    paymentStats: {
      credit: {
        total_sales: 0,
        net_sales: 0,
        total_shipping: 0,
        total_tax: 0,
        total_discount: 0,
        total_orders: 0,
      },
      debit: {
        total_sales: 0,
        net_sales: 0,
        total_shipping: 0,
        total_tax: 0,
        total_discount: 0,
        total_orders: 0,
      },
      cash: {
        total_sales: 0,
        net_sales: 0,
        total_shipping: 0,
        total_tax: 0,
        total_discount: 0,
        total_orders: 0,
      },
      bacs: {
        total_sales: 0,
        net_sales: 0,
        total_shipping: 0,
        total_tax: 0,
        total_discount: 0,
        total_orders: 0,
      },
      other: {
        total_sales: 0,
        net_sales: 0,
        total_shipping: 0,
        total_tax: 0,
        total_discount: 0,
        total_orders: 0,
      },
    },
    origin: { pos: 0, web: 0 },
  };
  const [report, setReport] = useState<ReportSales>(initState);
  const [orders, setOrders] = useState<Order[]>([]);

  const loadOrders = async () => {
    setCharging(true);
    let next = true;
    let parameters = {
      per_page: 20,
      page: orderPage,
      before: day,
      after: day,
    };
    await axios
      .get("https://toppet.cl/wp-json/wcfmmp/v1/orders/", {
        params: parameters,
        headers: { Authorization: authToken },
      })
      .then((response) => {
        //dsads
        let ordersTemp: Order[] = [];
        let ordersT2 = orders;
        let reportTemp: ReportSales = initState;
        setOrders(ordersTemp);

        if (orderPage > 1) {
          ordersTemp = ordersTemp.concat(ordersT2);
          reportTemp = report;
        }
        if (response.data.length < parameters.per_page) {
          next = false;
        }
        response.data.map((o: Order, index: number) => {
          reportTemp.total_orders += 1;

          if (o.status == "completed" || o.status == "processing") {
            reportTemp.total_sales += +o.total;
            reportTemp.net_sales += +o.total - +o.total_tax;
            reportTemp.total_tax += +o.total_tax;
            reportTemp.total_shipping += +o.shipping_total;
            reportTemp.total_discount += +o.discount_total;
            reportTemp.total_orders_complete += 1;
            reportTemp.total_items += o.line_items.length;

            if (o.payment_method == "credit") {
              reportTemp.paymentStats.credit.total_sales += +o.total;
              reportTemp.paymentStats.credit.net_sales +=
                +o.total - +o.total_tax;
              reportTemp.paymentStats.credit.total_tax += +o.total_tax;
              reportTemp.paymentStats.credit.total_shipping +=
                +o.shipping_total;
              reportTemp.paymentStats.credit.total_discount +=
                +o.discount_total;
              reportTemp.paymentStats.credit.total_orders += 1;
            } else if (o.payment_method == "debit") {
              reportTemp.paymentStats.debit.total_sales += +o.total;
              reportTemp.paymentStats.debit.net_sales +=
                +o.total - +o.total_tax;
              reportTemp.paymentStats.debit.total_tax += +o.total_tax;
              reportTemp.paymentStats.debit.total_shipping += +o.shipping_total;
              reportTemp.paymentStats.debit.total_discount += +o.discount_total;
              reportTemp.paymentStats.debit.total_orders += 1;
            } else if (o.payment_method == "cash") {
              reportTemp.paymentStats.cash.total_sales += +o.total;
              reportTemp.paymentStats.cash.net_sales += +o.total - +o.total_tax;
              reportTemp.paymentStats.cash.total_tax += +o.total_tax;
              reportTemp.paymentStats.cash.total_shipping += +o.shipping_total;
              reportTemp.paymentStats.cash.total_discount += +o.discount_total;
              reportTemp.paymentStats.cash.total_orders += 1;
            } else if (o.payment_method == "bacs") {
              reportTemp.paymentStats.bacs.total_sales += +o.total;
              reportTemp.paymentStats.bacs.net_sales += +o.total - +o.total_tax;
              reportTemp.paymentStats.bacs.total_tax += +o.total_tax;
              reportTemp.paymentStats.bacs.total_shipping += +o.shipping_total;
              reportTemp.paymentStats.bacs.total_discount += +o.discount_total;
              reportTemp.paymentStats.bacs.total_orders += 1;
            } else {
              reportTemp.paymentStats.other.total_sales += +o.total;
              reportTemp.paymentStats.other.net_sales +=
                +o.total - +o.total_tax;
              reportTemp.paymentStats.other.total_tax += +o.total_tax;
              reportTemp.paymentStats.other.total_shipping += +o.shipping_total;
              reportTemp.paymentStats.other.total_discount += +o.discount_total;
              reportTemp.paymentStats.other.total_orders += 1;
            }
          }
          //o.meta_data.find((ind) => ind.key == "_created_via_chpos")?.value =="1"
          if (o.created_via == "rest-api") {
            reportTemp.origin.pos += 1;
            o.origin = "POS";
          } else {
            reportTemp.origin.web += 1;
            o.origin = "WEB";
          }

          ordersTemp = ordersTemp.concat(o);
        });

        //actualizar ordenes
        setOrders(ordersTemp);
        setReport(reportTemp);
      })
      .catch((error) => {
        next = false;
        openPresent(error.toString(), true, "danger", "", 3000);
      })
      .finally(() => {
        setCharging(false);
        if (next) {
          let page = orderPage;
          setOrderPage(page + 1);
        }

        /*let opsTemp1=ops;
        setOps(opsTemp++);*/
      });
  };
  useEffect(() => {
    loadOrders();
  }, [orderPage]);
  useEffect(() => {
    if (!firstExe) {
      setOrders([]);
      setReport(initState);
      if (orderPage == 1) {
        loadOrders();
      } else {
        setOrderPage(1);
      }
    }
  }, [day]);
  useEffect(() => {
    setFirstExe(false);
  }, []);
  const seeOrder = (o: Order) => {
    history.push({ pathname: "/order", state: o });
  };
  const modOrders = (orderId: number, field: string, value: string) => {
    setCharging(true);
    let index = orders.findIndex((o) => o.id == orderId);
    let tempOrders = orders;

    if (field == "status") {
      tempOrders[index]["status"] = value;
    } else if (field == "payment") {
      tempOrders[index]["payment_method"] = value;
      tempOrders[index]["payment_method_title"] = payMethodTitle(value);
    }
    setOrders(tempOrders);
    setCharging(false);
  };
  return (
    <IonPage>
      <Header />
      <IonContent id="reports" fullscreen>
        <IonToolbar>
          <IonItem slot="start">
            <IonTitle>
              <IonText>Resumen del día </IonText>
            </IonTitle>
            <IonTitle>
              <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
              <IonPopover keepContentsMounted={true}>
                <IonDatetime
                  id="datetime"
                  color="medium"
                  value={day}
                  presentation="date"
                  onIonChange={(val) => {
                    setDay(val.detail.value + "");
                  }}
                  locale="es-CL"
                  max={today}
                  showDefaultButtons={true}
                  doneText="Aceptar"
                  cancelText="Cancelar"
                ></IonDatetime>
              </IonPopover>
            </IonTitle>
            <IonButton id="box-closing" color="warning" disabled={charging}>
              Cerrar caja
            </IonButton>
            <IonModal ref={modal} showBackdrop={true} trigger="box-closing">
              <IonHeader>
                <IonToolbar color="dark">
                  <IonTitle>Cierre de caja</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonGrid className="grid-to-print to-print-closing-box">
                <IonRow>
                  <IonCol className="ion-text-center">
                    <div id="to-print" className="ion-text-left">
                      <table>
                        <tr>
                          <td colSpan={2}>TOPPET SPA</td>
                        </tr>

                        <tr>
                          <td colSpan={2} className="border-bottom">
                            Ticket de cierre de caja
                          </td>
                        </tr>
                        <tr>
                          <td className="csh-padding-top">Fecha:</td>
                          <td className="csh-padding-top ion-text-right">
                            {format(parseISO(day), "dd/MM/yyyy")}
                          </td>
                        </tr>
                        <tr>
                          <td>Sucursal: </td>
                          <td className="ion-text-right">
                            {" " + userAuth?.store_display_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-bottom">Vendedor: </td>
                          <td className="border-bottom ion-text-right">
                            {" " + userAuth?.user_display_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="csh-padding-top">Pedidos: </td>
                          <td className="csh-padding-top ion-text-right">
                            {" " + report.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="ion-text-center">
                            Por medio de pago{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>Crédito: </td>
                          <td className="ion-text-right">
                            {report.paymentStats.credit.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td>Débito: </td>
                          <td className="ion-text-right">
                            {report.paymentStats.debit.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td>Efectivo: </td>
                          <td className="ion-text-right">
                            {report.paymentStats.cash.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td>Transferencia: </td>
                          <td className="ion-text-right">
                            {report.paymentStats.bacs.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-bottom">Otro: </td>
                          <td className="border-bottom ion-text-right">
                            {report.paymentStats.other.total_orders}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="csh-padding-top ion-text-center"
                          >
                            Facturación por medio
                          </td>
                        </tr>
                        <tr>
                          <td>Crédito: </td>
                          <td className="ion-text-right">
                            {getFormatter(
                              report.paymentStats.credit.total_sales
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Débito: </td>
                          <td className="ion-text-right">
                            {getFormatter(
                              report.paymentStats.debit.total_sales
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Efectivo: </td>
                          <td className="ion-text-right">
                            {getFormatter(report.paymentStats.cash.total_sales)}
                          </td>
                        </tr>
                        <tr>
                          <td>Transferencia: </td>
                          <td className="ion-text-right">
                            {getFormatter(report.paymentStats.bacs.total_sales)}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-bottom">Otro: </td>
                          <td className="border-bottom ion-text-right">
                            {getFormatter(
                              report.paymentStats.other.total_sales
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="csh-padding-top">
                            Facturación Total:{" "}
                          </td>
                          <td className="csh-padding-top ion-text-right">
                            {getFormatter(report.total_sales | 0)}
                          </td>
                        </tr>
                        <tr>
                          <td>Envío: </td>
                          <td className="ion-text-right">
                            {getFormatter(report.total_shipping | 0)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="border-bottom">
                            &nbsp;{" "}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="ion-text-center">
                            Firma del vendedor
                          </td>
                        </tr>
                      </table>
                    </div>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      size="large"
                      expand="full"
                      color="dark"
                      onClick={() => {
                        printJS({
                          printable: "to-print",
                          type: "html",
                          documentTitle: "TOPPET",
                          maxWidth: 200,
                          style:
                            "#to-print{font-size:8px !important;line-height:1.2;max-width:200px}#to-print td{font-size:8px !important;}.m-5{margin-top:3px;margin-bottom:3px;}#to-print img{width:130px;margin-left:auto;margin-right:auto;}td{border:0}td.border-bottom{padding-bottom: 3px;border-bottom:2px solid black;}th{text-align:left}td.pt-3,th.pt-3{padding-top:7px}#to-print table{width:200px;max-width:200px;}",
                        });
                      }}
                    >
                      <IonIcon slot="start" icon={printOutline} /> Imprimir{" "}
                    </IonButton>
                    <IonButton
                      size="large"
                      expand="full"
                      color="light"
                      onClick={() => {
                        dismiss();
                      }}
                    >
                      <IonIcon slot="start" icon={close} /> Cerrar{" "}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonModal>
          </IonItem>
          <IonItem lines="none" className="d-flex ion-justify-content-center">
            {charging ? (
              <>
                <IonSpinner color="dark" name="lines" />{" "}
                <IonLabel> &nbsp;Cargando los datos ...</IonLabel>
              </>
            ) : (
              ""
            )}
          </IonItem>

          <IonButton
            className="ion-padding-end"
            slot="end"
            color="dark"
            onClick={() => {
              orderPage == 1 ? loadOrders() : setOrderPage(1);
            }}
          >
            Actualizar datos
          </IonButton>
        </IonToolbar>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItemDivider>
                <IonLabel>
                  <strong>Total de ventas</strong>
                </IonLabel>
              </IonItemDivider>
              <div className="ch-flex">
                <IonItem>
                  <IonLabel className="ion-no-margin">
                    <p>Ventas Brutas</p>
                    <h3 className="ion-text-end">
                      {getFormatter(report?.total_sales | 0)}
                    </h3>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-no-margin">
                    <p>Ventas Netas</p>
                    <h3 className="ion-text-end">
                      {getFormatter(report?.net_sales | 0)}
                    </h3>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-no-margin">
                    <p>Impuesto Total</p>
                    <h3 className="ion-text-end">
                      {getFormatter(report?.total_tax | 0)}
                    </h3>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-no-margin">
                    <p>Envío Total</p>
                    <h3 className="ion-text-end">
                      {getFormatter(report?.total_shipping | 0)}
                    </h3>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-no-margin">
                    <p>Descuento Total</p>
                    <h3 className="ion-text-end">
                      {getFormatter(report?.total_discount | 0)}
                    </h3>
                  </IonLabel>
                </IonItem>
              </div>
              {report.paymentStats.credit.total_sales > 0 && (
                <>
                  <IonItemDivider>
                    <IonLabel>
                      <strong>Crédito</strong>
                    </IonLabel>
                  </IonItemDivider>
                  <div className="ch-flex">
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Brutas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.credit.total_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Netas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.credit.net_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Impuesto Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.credit.total_tax | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Envío Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.credit.total_shipping | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Descuento Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.credit.total_discount | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  </div>
                </>
              )}
              {report.paymentStats.debit.total_sales > 0 && (
                <>
                  <IonItemDivider>
                    <IonLabel>
                      <strong>Débito</strong>
                    </IonLabel>
                  </IonItemDivider>
                  <div className="ch-flex">
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Brutas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.debit.total_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Netas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.debit.net_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Impuesto Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.debit.total_tax | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Envío Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.debit.total_shipping | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Descuento Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.debit.total_discount | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  </div>
                </>
              )}
              {report.paymentStats.cash.total_sales > 0 && (
                <>
                  <IonItemDivider>
                    <IonLabel>
                      <strong>Efectivo</strong>
                    </IonLabel>
                  </IonItemDivider>
                  <div className="ch-flex">
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Brutas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.cash.total_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Netas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.cash.net_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Impuesto Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.cash.total_tax | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Envío Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.cash.total_shipping | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Descuento Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.cash.total_discount | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  </div>
                </>
              )}
              {report.paymentStats.bacs.total_sales > 0 && (
                <>
                  <IonItemDivider>
                    <IonLabel>
                      <strong>Transferencia</strong>
                    </IonLabel>
                  </IonItemDivider>
                  <div className="ch-flex">
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Brutas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.bacs.total_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Netas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.bacs.net_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Impuesto Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.bacs.total_tax | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Envío Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.bacs.total_shipping | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Descuento Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.bacs.total_discount | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  </div>
                </>
              )}
              {report.paymentStats.other.total_sales > 0 && (
                <>
                  <IonItemDivider>
                    <IonLabel>
                      <strong>Otros</strong>
                    </IonLabel>
                  </IonItemDivider>
                  <div className="ch-flex">
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Brutas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.other.total_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Ventas Netas</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.other.net_sales | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Impuesto Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.other.total_tax | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Envío Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.other.total_shipping | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel className="ion-no-margin">
                        <p>Descuento Total</p>
                        <h3 className="ion-text-end">
                          {getFormatter(
                            report?.paymentStats.other.total_discount | 0
                          )}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  </div>
                </>
              )}
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>Detalles de los pedidos</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="order-details">
                  <IonRow>
                    <IonCol className="ion-text-start">
                      <strong>Total</strong>
                    </IonCol>{" "}
                    <IonCol className="ion-text-end">
                      <strong>{report?.total_orders | 0}</strong>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-start ion-padding-start">
                      Completados o Procesándose
                    </IonCol>{" "}
                    <IonCol className="ion-text-end">
                      {report?.total_orders_complete | 0}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-start ion-padding-start">
                      Productos Vendidos
                    </IonCol>{" "}
                    <IonCol className="ion-text-end">
                      {report?.total_items | 0}
                    </IonCol>
                  </IonRow>
                  <IonItemDivider></IonItemDivider>
                  <IonRow>
                    <IonCol>
                      <IonRow>
                        <IonCol className="ion-padding-end">
                          <strong>Medio de pago</strong>

                          {report.paymentStats.credit.total_orders > 0 && (
                            <IonRow>
                              <IonCol className="ion-text-start ion-padding-start">
                                Crédito
                              </IonCol>
                              <IonCol size="auto" className="ion-text-end">
                                {report.paymentStats.credit.total_orders}
                              </IonCol>
                            </IonRow>
                          )}
                          {report.paymentStats.debit.total_orders > 0 && (
                            <IonRow>
                              <IonCol className="ion-text-start ion-padding-start">
                                Débito
                              </IonCol>
                              <IonCol size="auto" className="ion-text-end">
                                {report.paymentStats.debit.total_orders}
                              </IonCol>
                            </IonRow>
                          )}
                          {report.paymentStats.cash.total_orders > 0 && (
                            <IonRow>
                              <IonCol className="ion-text-start ion-padding-start">
                                Efectivo
                              </IonCol>
                              <IonCol size="auto" className="ion-text-end">
                                {report.paymentStats.cash.total_orders}
                              </IonCol>
                            </IonRow>
                          )}
                          {report.paymentStats.bacs.total_orders > 0 && (
                            <IonRow>
                              <IonCol className="ion-text-start ion-padding-start">
                                Transferencia
                              </IonCol>
                              <IonCol size="auto" className="ion-text-end">
                                {report.paymentStats.bacs.total_orders}
                              </IonCol>
                            </IonRow>
                          )}
                          {report.paymentStats.other.total_orders > 0 && (
                            <IonRow>
                              <IonCol className="ion-text-start ion-padding-start">
                                Otros
                              </IonCol>
                              <IonCol size="auto" className="ion-text-end">
                                {report.paymentStats.other.total_orders}
                              </IonCol>
                            </IonRow>
                          )}
                        </IonCol>
                        <IonCol className="ion-padding-start">
                          <strong>Origen</strong>
                          <IonRow>
                            <IonCol className="ion-text-start ion-padding-start">
                              POS
                            </IonCol>
                            <IonCol size="auto" className="ion-text-end">
                              {report.origin.pos}
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol className="ion-text-start ion-padding-start">
                              WEB
                            </IonCol>
                            <IonCol size="auto" className="ion-text-end">
                              {report.origin.web}
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonCard>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Fecha</th>
                <th>Bruto</th>
                <th>Neto</th>
                <th>Impuesto</th>
                <th>Envío</th>
                <th>Descuento</th>
                <th>Productos</th>
                <th>Origen</th>
                <th>Método</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((o: Order, index) => {
                return (
                  <tr
                    key={index}
                    /*onClick={() => seeOrder(o)}*/
                    title="Click para ver más detalles del pedido"
                  >
                    <th onClick={() => seeOrder(o)}>{index + 1}</th>
                    <th onClick={() => seeOrder(o)}>{o.id}</th>
                    <td onClick={() => seeOrder(o)}>
                      {format(parseISO(o.date_created + ""), "dd/MM/yyyy")}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {getFormatter(+o.total | 0)}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {getFormatter((+o.total - +o.total_tax) | 0)}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {getFormatter(+o.total_tax | 0)}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {getFormatter(+o.shipping_total | 0)}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {getFormatter(+o.discount_total | 0)}
                    </td>
                    <td onClick={() => seeOrder(o)}>
                      {+o.line_items.length | 0}
                    </td>
                    <td onClick={() => seeOrder(o)}>{o.origin}</td>
                    <td onClick={() => seeOrder(o)}>
                      {o.payment_method_title}
                    </td>
                    <td>
                      <OrderStatus
                        status={o.status}
                        orderID={o.id}
                        modOrders={modOrders}
                      />
                    </td>
                  </tr>
                );
              })}
              {orders.length == 0 ? (
                <tr>
                  <td colSpan={12}>Sin datos...</td>
                </tr>
              ) : (
                <tr>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Reports;
