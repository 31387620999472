import { createContext } from 'react';
import { Product, User } from '../../modals/modals';


export interface AppContextType {
    userAuth: User|null;
    saveUserAuth: (user:User|null) => void;
    openPresent: (text:string,error:boolean,color:string,href:string,time:number)=>void;
    /*products: Product[]|null;
    saveProducts: (product:Product) => void;
    cart:*/
}

const AppContext = createContext<AppContextType>({ userAuth: null, saveUserAuth: (user:User|null)=>{}, openPresent:(text:string,error:boolean,color:string,href:string,time:number)=>{}/*,token: null, saveToken: (token:string)=>{}*/});
export default AppContext;
/*export const useIsFirstTimeApp = () => useContext(AppContext);*/