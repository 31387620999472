import {
  Attributes,
  Billing,
  Category,
  Customer,
  LineItem,
  Order,
  OrderStatusModel,
  Product,
  Shipping,
  User,
  Variation,
} from "../modals/modals";
import axios from "axios";
import { type } from "os";
/*let urlAdd = "add.mp3";
let audioAdd = new Audio(urlAdd);
let urlError = "assets/sounds/error.m4a";
let audioError = new Audio(urlError);*/

export const errorReturnMessage = (message: string) => {
  var returnMessage = [];
  returnMessage[0] = "Error";
  returnMessage[1] = message;
  //returnMessage[1] = "Lo sentimos, compruebe su conexión y sus credenciales e inténtelo de nuevo";
  switch (message) {
    case "Request failed with status code 400": {
      returnMessage[0] = "Credenciales Incorrectas";
      returnMessage[1] = "Rectefique su usuario y contraseña.";
      break;
    }
    case "Error: Network Error": {
      returnMessage[0] = "Error de red";
      returnMessage[1] = "Compruebe su conexión a internet.";
      break;
    }
    case "Error: Request failed with status code 403": {
      returnMessage[0] = "Error 403. Credenciales inválidas";
      returnMessage[1] =
        "Email y/o contraseña incorrecto. Inténtelo nuevamente.";
      break;
    }
    case "not vendor": {
      returnMessage[0] = "Error de permisos";
      returnMessage[1] = "El sistema es solo para PYMES.";
      break;
    }
    case "not data": {
      returnMessage[0] = "Error en los datos";
      returnMessage[1] = "Por favor rectifique sus datos de entrada.";
      break;
    }
    default: {
      break;
    }
  }
  return returnMessage;
};

export const returnUser = (object: User) => {
  const listRoles = object.roles.map((rol: string) => rol);
  const user: User = {
    token: object.token,
    user_display_name: object.user_display_name,
    user_email: object.user_email,
    user_nicename: object.user_nicename,
    store_id: object.store_id,
    store_name: object.store_name,
    roles: listRoles,
    code: object.code,
    store_display_name: object.store_display_name,
    store_address: object.store_address,
    store_token: object.store_token != "" ? object.store_token : object.token,
    client_key: object.client_key,
    client_secret: object.client_secret,
  };

  return user;
};
export const sendCustomer = async (
  authToken: string,
  email: string,
  phone: string,
  name: string,
  last: string,
  address1: string,
  address2: string,
  country: string,
  region: string,
  city: string,
  postcode: string
) => {
  const data = {
    email: email,
    first_name: name,
    last_name: last,
    billing: {
      first_name: name,
      last_name: last,
      company: "",
      address_1: address1,
      address_2: address2,
      city: city,
      state: region,
      postcode: postcode,
      country: country,
      email: email,
      phone: phone,
    },
    shipping: {
      first_name: name,
      last_name: last,
      company: "",
      address_1: address1,
      address_2: address2,
      city: city,
      state: region,
      postcode: postcode,
      country: country,
    },
  };
  return await axios.post("https://toppet.cl/wp-json/wc/v3/customers", data, {
    headers: { Authorization: authToken },
  });
};
/*
export const returnProduct = (object: Product) => {

	const product: Product = {
		id: object.id,
		name: object.name,
		slug: object.slug,
		permalink: object.permalink,
		date_created: object.date_created,
		date_created_gmt: object.date_created_gmt,
		date_modified: object.date_modified,
		date_modified_gmt: object.date_modified_gmt,
		type: object.type,
		status: object.status,
		featured: object.featured,
		catalog_visibility: object.catalog_visibility,
		description: object.description,
		short_description: object.short_description,
		sku: object.sku,
		price: object.price,
		regular_price: object.regular_price,
		sale_price: object.sale_price,
		date_on_sale_from: object.date_on_sale_from,
		date_on_sale_from_gmt: object.date_on_sale_from_gmt,
		date_on_sale_to: object.date_on_sale_to,
		date_on_sale_to_gmt: object.date_on_sale_to_gmt,
		price_html: object.price_html,
		on_sale: object.on_sale,
		purchasable: object.purchasable,
		total_sales: object.total_sales,
		virtual: object.virtual,
		downloadable: object.downloadable,
		downloads: object.downloads,
		download_limit: object.download_limit,
		download_expiry: object.download_expiry,
		external_url: object.external_url,
		button_text: object.button_text,
		tax_status: object.tax_status,
		tax_class: object.tax_class,
		manage_stock: object.manage_stock,
		stock_quantity: object.stock_quantity,
		stock_status: object.stock_status,
		backorders: object.backorders,
		backorders_allowed: object.backorders_allowed,
		backordered: object.backordered,
		sold_individually: object.sold_individually,
		weight: object.weight,
		dimensions: object.dimensions,
		shipping_required: object.shipping_required,
		shipping_taxable: object.shipping_taxable,
		shipping_class: object.shipping_class,
		shipping_class_id: object.shipping_class_id,
		reviews_allowed: object.reviews_allowed,
		average_rating: object.average_rating,
		rating_count: object.rating_count,
		related_ids: object.related_ids,
		upsell_ids: object.upsell_ids,
		cross_sell_ids: object.cross_sell_ids,
		parent_id: object.parent_id,
		purchase_note: object.purchase_note,
		categories: object.categories,
		tags: object.tags,
		images: object.images,
		attributes: object.attributes,
		default_attributes: object.default_attributes,
		variations: object.variations,
		grouped_products: object.grouped_products,
		menu_order: object.menu_order,
		meta_data: object.meta_data,
		_links: object._links
	};
	return product;
}*/
export const returnLineItem = (
  lineItemArr: LineItem[],
  product: Product,
  identifier: string,
  operation: string
) => {
  let tempLIA = lineItemArr;
  let inArray = false;
  let productQ = Number(product.stock_quantity);
  if (productQ == 0) {
    productQ = 100000;
  }

  lineItemArr.map((li, index) => {
    if (identifier == "id") {
      if (li.product_id == product.id) {
        inArray = true;
        if (operation == "add" && li.quantity < productQ) {
          tempLIA[index].quantity = li.quantity + 1;
        } else if (operation == "less") {
          li.quantity > 1
            ? (tempLIA[index].quantity = li.quantity - 1)
            : (tempLIA[index].quantity = li.quantity);
        }
      }
    }
  });
  if (!inArray && operation == "add") {
    const lineItem: LineItem = {
      name: product.name,
      product_id: product.id,
      quantity: 1,
      sku: product.sku,
      price: +product.price,
      subtotal_tax: product.tax_amount,
    };
    tempLIA.push(lineItem);
  }

  return tempLIA;
};
export const returnLineItemV2 = (
  lineItemArr: LineItem[],
  product: Product,
  identifier: string,
  operation: string
) => {
  let indexP = lineItemArr.findIndex((li) => li.product_id == product.id);
  if (indexP < 0) {
    //if(product){ Agregar filtro cantidad de productos**************************************************
    console.log(product);
    let productQ = Number(product.stock_quantity);
    if (productQ == 0 && product.stock_status == "instock") {
      productQ = 100000;
    }
    if (operation == "add" && productQ > 0) {
      let lineItem: LineItem = {
        name: product.name,
        product_id: product.id,
        quantity: 1,
        price: +product.price /*,
				subtotal:""+(+product.price),
				subtotal_tax:""+getIVA(+product.price)*/,
      };
      lineItemArr.push(lineItem);
      //const [play] = useSound("");
      //audioAdd.play();
    } else {
      //audioError.play();
    }
  } else {
    let productQ = Number(product.stock_quantity);
    if (productQ == 0 && product.stock_status == "instock") {
      productQ = 100000;
    }
    if (operation == "add" && lineItemArr[indexP].quantity < productQ) {
      lineItemArr[indexP].quantity += 1;
      //audioAdd.play();
      /*lineItemArr[indexP].subtotal = ""+(+product.price)*lineItemArr[indexP].quantity;
			lineItemArr[indexP].subtotal_tax = ""+getIVA(+product.price)*lineItemArr[indexP].quantity;*/
    } else {
      //audioError.play();
    }
  }
  console.log(lineItemArr);
  return lineItemArr;
};
export const returnLineItemV3 = (
  lineItemArr: LineItem[],
  product: Product,
  variation: Variation | null,
  playAdd: () => void,
  playError: () => void
) => {
  let indexP = lineItemArr.findIndex(
    (li) =>
      li.product_id == product.id &&
      (variation == null || li.variation_id == variation?.id)
  );
  if (indexP < 0) {
    //if(product){ Agregar filtro cantidad de productos**************************************************
    //console.log(product);
    let productQ = Number(product.stock_quantity);
    if (productQ == 0 && product.stock_status == "instock") {
      productQ = 100000;
    }
    if (productQ > 0) {
      let lineItem: LineItem = {
        name: product.name,
        product_id: product.id,
        quantity: 1,
        price: variation ? +variation.price : +product.price,
        variation_id: variation ? variation.id : undefined,
      };
      lineItemArr.push(lineItem);
      playAdd();
    } else {
      playError();
    }
  } else {
    let productQ = Number(product.stock_quantity);
    if (productQ == 0 && product.stock_status == "instock") {
      productQ = 100000;
    }
    if (lineItemArr[indexP].quantity < productQ) {
      lineItemArr[indexP].quantity += 1;
      playAdd();
      //audioAdd.play();
      /*lineItemArr[indexP].subtotal = ""+(+product.price)*lineItemArr[indexP].quantity;
			lineItemArr[indexP].subtotal_tax = ""+getIVA(+product.price)*lineItemArr[indexP].quantity;*/
    } else {
      playError();
      //audioError.play();
    }
  }
  console.log(lineItemArr);
  return lineItemArr;
};
export const returnLineItemV4 = (
  lineItemArr: LineItem[],
  product: Product,
  variation: Variation | null,
  playAdd: () => void,
  playError: () => void
) => {
  let indexP = lineItemArr.findIndex(
    (li) =>
      li.product_id == product.id &&
      (variation == null || li.variation_id == variation?.id)
  );
  if (indexP < 0) {
    if (
      (((product.stock_status === "instock" &&
        product.manage_stock === false) ||
        (product.stock_quantity > 0 && product.manage_stock === true)) &&
        variation == null) ||
      (variation != null &&
        ((variation.stock_status === "instock" &&
          variation.manage_stock === false) ||
          (variation.stock_quantity > 0 && variation.manage_stock === true)))
    ) {
      let lineItem: LineItem = {
        name: variation
          ? product.name + " - " + getAttributesName(variation)
          : product.name,
        product_id: product.id,
        quantity: 1,
        price: variation ? +variation.price : +product.price,
        variation_id: variation ? variation.id : undefined,
      };
      lineItemArr.push(lineItem);
      playAdd();
    } else {
      playError();
    }
  } else {
    if (canAddItem(product, variation, lineItemArr[indexP])) {
      lineItemArr[indexP].quantity += 1;
      playAdd();
      //audioAdd.play();
      /*lineItemArr[indexP].subtotal = ""+(+product.price)*lineItemArr[indexP].quantity;
			lineItemArr[indexP].subtotal_tax = ""+getIVA(+product.price)*lineItemArr[indexP].quantity;*/
    } else {
      playError();
      //audioError.play();
    }
  }
  return lineItemArr;
};

export const canAddItem = (
  product: Product | undefined,
  variation: Variation | null,
  lineItem: LineItem
) => {
  console.log(variation);

  if (
    (product != undefined &&
      ((product.stock_status === "instock" && product.manage_stock === false) ||
        (product.stock_quantity > lineItem.quantity &&
          product.manage_stock === true)) &&
      variation == null) ||
    (variation != null &&
      ((variation.stock_status === "instock" &&
        variation.manage_stock === false) ||
        (variation.stock_quantity > lineItem.quantity &&
          variation.manage_stock === true)))
  ) {
    return true;
  }
  return false;
};
export const getAttributesName = (v: Variation) => {
  let attributes = "";
  v.attributes.map((a: Attributes) => {
    attributes = attributes + (a.name + ": " + a.option + " ");
  });
  return attributes;
};
export const isVendor = (user: User) => {
  if (
    user.roles.includes("wcfm_vendor") ||
    user.roles.includes("shop_staff") ||
    user.roles.includes("administrator") ||
    user.roles.includes("shop_manager")
  ) {
    return true;
  }
  return false;
};

export const imageUrl = (url: string) => {
  let newUrl = url.substring(0, url.lastIndexOf("."));
  let extUrl = url.substring(url.lastIndexOf("."));
  return newUrl + "-150x150" + extUrl;
};

export const reorderCategoryList = (objects: Category[]) => {
  /*let ordered: Category[] = [];
	objects.map(() => {

	});*/
  objects.sort((a, b) => {
    let aCompare = (a.parent_id ? a.parent_id + "" : "") + a.id + "";
    let bCompare = (b.parent_id ? b.parent_id + "" : "") + b.id + "";
    if (aCompare > bCompare) {
      return 1;
    }
    if (aCompare < bCompare) {
      return -1;
    }
    return 0;
  });
  return objects;
};
//Axios
/*Productos*/
//---Get Productos
export const getProductos = async (ck: string, cs: string, page: number) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wc/v3/products/?status=publish&stock_status=instock&per_page=700&page=" +
      page,
    {
      auth: {
        username: ck,
        password: cs,
      },
    }
  );
};
/*Categorias*/
//---Get Categorias
export const getCategories = async (ck: string, cs: string, id: number) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wc/v3/products/categories/?per_page=50&hide_empty=true&exclude=1984&orderby=name&parent=" +
      id,
    { auth: { username: ck, password: cs } }
  );
};
/*Shipping Methods*/
//---Get Shipping Methods
export const getShippingMethods = async (authToken: string) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wc/v3/shipping/zones/1/methods",
    { headers: { Authorization: authToken } }
  );
};
/*Orders*/
//---Get Orders
export const getOrders = async (
  authToken: string,
  page: number,
  after: string,
  before: string
) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wcfmmp/v1/orders/?per_page=50&after=" +
      after +
      "&before=" +
      before +
      "&page=" +
      page,
    { headers: { Authorization: authToken } }
  );
};
/*Orders Notes*/
//---Get Orders Notes
export const getNotes = async (authToken: string, id: number) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wc/v3/orders/" + id + "/notes",
    { headers: { Authorization: authToken } }
  );
};
/*Variations*/
//---Get Variations
export const getVariations = async (
  ck: string,
  cs: string,
  id: number,
  page: number
) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wc/v3/products/" +
      id +
      "/variations/?page=" +
      page,
    { auth: { username: ck, password: cs } }
  );
};
export const getAllVariations = async (ck: string, cs: string, id: number) => {
  let variations: Variation[] = [];
  let totalpages = 1;
  await getVariations(ck, cs, id, 1).then((res) => {
    variations = res.data;
    totalpages = res.headers["x-wp-totalpages"];
  });
  for (let page = 2; page <= totalpages; page++) {
    // eslint-disable-next-line no-loop-func
    await getVariations(ck, cs, id, page).then((res2) => {
      let tempVar: Variation[] = res2.data;
      variations.concat(tempVar);
    });
  }
  return variations;
};
//Get Store
export const getStore = async (authToken: string, id: number) => {
  return await axios.get(
    "https://toppet.cl/wp-json/wcfmmp/v1/settings/id/" + id,
    { headers: { Authorization: authToken } }
  );
};
export const isCategoryOfProduct = (catId: number, categories: Category[]) => {
  let res = false;
  categories.map((c: Category) => {
    if (c.id == catId) {
      res = true;
    }
  });
  return res;
};
export const payMethodTitle = (payMethod: string) => {
  switch (payMethod) {
    case "bacs":
      return "Transferecia Bancaria";
    case "cash":
      return "Efectivo";
    case "debit":
      return "Débito";
    case "credit":
      return "Crédito";
    default:
      return "Efectivo";
  }
};
export const modLineItems = (lineItems: LineItem[]) => {
  let modLineItems: LineItem[] = [];
  lineItems.map((li: LineItem, index) => {
    modLineItems.push({ product_id: li.product_id, quantity: li.quantity });
  });
  return modLineItems;
};

export const sendOrderFunction = async (
  authToken: string,
  lineItems: LineItem[],
  payMethod: string,
  user: User | null,
  customer: Customer | null,
  shipping: string,
  shippingCost: string,
  discount: string
) => {
  let customerId: number = 0;
  let customerBilling = {};
  let customerShipping = {};

  if (customer) {
    customerId = customer.id;
    customer.billing.email = customer.email;
    customerBilling = customer.billing;
    customerShipping = customer.shipping;
  }
  let arrCode = user?.code.split("_");
  let couponCode = +discount > 0 ? [{ code: arrCode ? arrCode[1] : "" }] : [];
  const data = {
    created_via: "chpos",
    payment_method: payMethod,
    payment_method_title: payMethodTitle(payMethod),
    coupon_lines: couponCode,
    set_paid: true,
    status: "completed",
    customer_id: customerId,
    billing: customerBilling,
    shipping: customerShipping,
    line_items: lineItems,
    shipping_lines: [
      {
        method_id: shipping,
        total: getPrice(+shippingCost) + "",
      },
    ],
    meta_data: [
      {
        key: "_lo_cajero_id",
        value: user?.store_id,
      },
      {
        key: "_lo_cajero_name",
        value: user?.store_name,
      },
      {
        key: "_created_via_chpos",
        value: true,
      },
    ],
  };
  if (+discount > 0) {
    /*let arrCode = user?.code.split("_");

    couponCode = [{ code: arrCode ? arrCode[1] : "" }];
    let prodsIds: number[] = [];
    lineItems.forEach((item: LineItem) => {
      prodsIds.push(item.product_id);
    });
    console.log(prodsIds);*/

    await axios.put(
      "https://toppet.cl/wp-json/wc/v3/coupons/" + (arrCode ? arrCode[0] : ""),
      { amount: discount, product_ids: [] },
      { headers: { Authorization: authToken } }
    );
    /*.then(async () => {
        return await axios.post(
          "https://toppet.cl/wp-json/wc/v3/orders",
          data,
          {
            headers: { Authorization: authToken },
          }
        );
      })*/
  }
  return await axios.post("https://toppet.cl/wp-json/wc/v3/orders", data, {
    headers: { Authorization: authToken },
  });
};

// Editar Método de pago
export const editOrderPayment = async (
  authToken: string,
  orderID: number,
  payMethod: string
) => {
  const data = {
    payment_method: payMethod,
    payment_method_title: payMethodTitle(payMethod),
  };

  return await axios.put(
    "https://toppet.cl/wp-json/wc/v3/orders/" + orderID,
    data,
    {
      headers: { Authorization: authToken },
    }
  );
};
// Editar Método de pago
export const editOrderStatus = async (
  authToken: string,
  orderID: number,
  status: string
) => {
  const data = {
    status: status,
  };

  return await axios.put(
    "https://toppet.cl/wp-json/wc/v3/orders/" + orderID,
    data,
    {
      headers: { Authorization: authToken },
    }
  );
};
const sendOrder = async (data: any, authToken: string) => {
  return await axios.post("https://toppet.cl/wp-json/wc/v3/orders", data, {
    headers: { Authorization: authToken },
  });
};

//Calcular precio con IVA
export const getPriceIVA = (precio: number) => {
  return precio * 1.19;
};
export const getIVA = (total: number) => {
  return total - total / 1.19;
};
export const getPrice = (total: number) => {
  return total / 1.19;
};
export const getTotal = (subtotal: number, shippingCost: number) => {
  return +getPriceIVA(subtotal).toFixed(0) + +shippingCost;
};
export const getDiscount = (type: string, val: string, subtotal: number) => {
  if (type === "percent") {
    return getPriceIVA((subtotal * +val) / 100).toFixed(0);
  }
  return val;
};
export const getDiscountPercent = (
  type: string,
  subtotal: number,
  discount: number
) => {
  if (type === "percent") return discount;
  return (getPrice(discount) * 100) / subtotal;
};
var formatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const getFormatter = (number: number) => {
  return formatter.format(number);
};
export const removeAccent = (texto: string) => {
  return texto
    .normalize("NFD")
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
};
export const compareOrderState = (o1: Order, o2: Order) => {
  if (
    (o1.status == "cancelled" ||
      o1.status == "refunded" ||
      o1.status == "failed" ||
      o1.status == "trash") &&
    (o2.status == "pending" ||
      o2.status == "processing" ||
      o2.status == "on-hold" ||
      o2.status == "completed")
  ) {
    return -1;
  }
  if (
    (o2.status == "cancelled" ||
      o2.status == "refunded" ||
      o2.status == "failed" ||
      o2.status == "trash") &&
    (o1.status == "pending" ||
      o1.status == "processing" ||
      o1.status == "on-hold" ||
      o1.status == "completed")
  ) {
    return 1;
  }
  return 0;
};

/*export const skeleton=(items:number)=>{
	for(let i=0;i<operations;i++){
		<IonItem>
		<IonThumbnail slot="start">
		 <IonSkeletonText animated />
		</IonThumbnail>
		<IonLabel><IonSkeletonText animated style={{ width: '60%' }} /></IonLabel>
		<IonText slot="end"  style={{ width: '30px' }}><IonSkeletonText animated style={{ width: '100%' }} /></IonText>
		<IonText slot="end"  style={{ width: '10px' }}><IonSkeletonText animated  style={{ width: '100%' }}/></IonText>
	  </IonItem>
	  }
}*/
export const orderStatusFunc = (status: string) => {
  let os: OrderStatusModel = { color: "", icon: "", text: "" };
  switch (status) {
    case "completed": {
      os.color = "primary";
      os.icon = "checkmarkCircleSharp";
      os.text = "Completado";
      break;
    }
    case "processing": {
      os.color = "success";
      os.icon = "checkmarkCircleSharp";
      os.text = "Procesando";
      break;
    }
    case "pending": {
      os.color = "medium";
      os.icon = "checkmarkCircleSharp";
      os.text = "Pendiente de pago";
      break;
    }
    case "on-hold": {
      os.color = "warning";
      os.icon = "checkmarkCircleSharp";
      os.text = "En espera";
      break;
    }
    case "cancelled": {
      os.color = "light";
      os.icon = "checkmarkCircleSharp";
      os.text = "Cancelado";
      break;
    }
    case "failed": {
      os.color = "danger";
      os.icon = "checkmarkCircleSharp";
      os.text = "Fallido";
      break;
    }
    case "refunded": {
      os.color = "danger";
      os.icon = "checkmarkCircleSharp";
      os.text = "Reembolsado";
      break;
    }
    default: {
      os.color = "success";
      os.icon = "checkmarkCircleSharp";
      os.text = "Completado";
      break;
    }
  }
  return os;
};

export const formatDateISO8601 = (date: Date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "T" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};
