import { Storage } from '@capacitor/storage';
import { User } from '../modals/modals';

export const setObject = async (name:string, object:any)=> {
  await Storage.set({
    key: name,
    value: JSON.stringify(object)
  });
}

// JSON "get" example
export const getObject = async (name:string)=> {
  const ret = await Storage.get({ key: name });
  const object = JSON.parse(ret.value || '{}');
  return object;
}

export const setItem = async (name:string, item:string)=> {
  await Storage.set({
    key: name,
    value: item
  });
}

export const getItem = async (name:string)=> {
  const { value } = await Storage.get({ key: 'name' });
  return value;
}

export const removeItem = async (name:string)=> {
  await Storage.remove({ key: name });
}

export const keys = async ()=> {
  const { keys } = await Storage.keys();
}

export const clear = async ()=> {
  await Storage.clear();
}
