import { IonBadge, IonButton, IonCard, IonCardContent, IonIcon, IonImg, IonLabel, IonText} from '@ionic/react';
import { addCircle, } from 'ionicons/icons';
import './ProductItem.css';
import { Product } from '../../modals/modals';
import { getFormatter, getPriceIVA, imageUrl } from '../../hooks/functions';

interface Props {
    product: Product;
}

const ProductItem = ({ product }: Props) => {

    return (

            <IonCard className="product-card" title={product.name+ (product.variations.length>0?" - "+product.variations.length+ " variaciones":"")}>

                <IonButton className="ion-hide"><IonIcon size="large" icon={addCircle} /></IonButton>
                <IonImg src={product.images[0]?imageUrl("" + product.images[0].src):"assets/images/toppet-black-low.jpg"}></IonImg>
                <IonCardContent>
                    <IonLabel><IonText>{product.name}</IonText></IonLabel>
                    <IonLabel className='ion-text-start'><strong>{getFormatter(+getPriceIVA(+product.price).toFixed(0))}</strong></IonLabel><IonBadge slot='end' color='dark'>{product.stock_quantity}</IonBadge>
                </IonCardContent>

            </IonCard>

    );
};

export default ProductItem;