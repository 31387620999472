import { IonAvatar, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRow, IonText, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import { camera, home, navigate, ribbon, star, qrCode, personCircleOutline, location, close, logoFacebook, logoInstagram, logoTwitter, logoLinkedin, barcodeOutline, analyticsOutline } from 'ionicons/icons';
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppContext from "../context/App/AppContext";
//import { Impresora } from '../utilities/impresora.js';
import { ConectorPlugin } from '../hooks/ConectorPlugin.js';

const Menu: React.FC = () => {
    const history = useHistory();
    const locationR = useLocation();
    const { userAuth, saveUserAuth } = useContext(AppContext);
    const cerrarSesion = () => {
        saveUserAuth(null);
        history.replace('/login');
    }
    /*const printTermal = () => {

        let nombreImpresora = "HP LaserJet 1020";
        //if (!nombreImpresora) return loguear("Selecciona una impresora");
        const conector = new ConectorPlugin();
        conector.texto("Hola mundo\n");
        conector.imprimirEn(nombreImpresora)
            .then(respuestaAlImprimir => {
                if (respuestaAlImprimir === true) {
                    console.log("Impreso correctamente");
                } else {
                    console.log("Error. La respuesta es: " + respuestaAlImprimir);
                }
            });
    }*/
    return (
        <>
            <IonMenu content-id="main-content">
                <IonHeader>
                    <IonToolbar color="warning">
                        <IonButtons >
                            <IonItem color="warning" className="" lines="none">
                                <IonLabel>&nbsp; {userAuth?.store_name}</IonLabel>
                            </IonItem>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonMenuToggle >
                                <IonButton>
                                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                                </IonButton>
                            </IonMenuToggle>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonMenuToggle auto-hide="false">
                            <IonItem button className={locationR.pathname === '/pos' ? 'selected' : ''} routerLink={'/pos'} routerDirection="none" detail={false}>
                                <IonIcon slot="start" icon={barcodeOutline}></IonIcon>
                                <IonLabel>
                                    POS
                                </IonLabel>
                            </IonItem>
                            <IonItem button className={locationR.pathname === '/informes' ? 'selected' : ''} routerLink={'/informes'} routerDirection="none" detail={false}>
                                <IonIcon slot="start" icon={analyticsOutline}></IonIcon>
                                <IonLabel>
                                    Informes
                                </IonLabel>
                            </IonItem>
                            {/*<IonItem button className={locationR.pathname === '/home' ? 'selected' : ''} routerLink={'/home'} routerDirection="none" detail={false}>
                                <IonIcon slot="start" icon={personCircleOutline}></IonIcon>
                                <IonLabel>
                                    Mi cuenta
                                </IonLabel>
                                </IonItem>*/}
                        </IonMenuToggle>
                    </IonList>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div className="ion-justify-content-center ch-flex">
                                    <IonMenuToggle className="ion-align-self-center">
                                        <IonButton color="dark" size="default" className="" onClick={cerrarSesion}>
                                            <IonLabel>Cerrar Sesión</IonLabel>
                                        </IonButton>
                                    </IonMenuToggle>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <div className="ion-justify-content-center ch-flex">
                                    <IonButton className="ion-align-self-center" size="small" color="medium" href="https://www.facebook.com/cashaychile" target="_blank">
                                        <IonIcon icon={logoFacebook}></IonIcon>
                                    </IonButton>
                                    <IonButton className="ion-align-self-center" size="small" color="medium" href="https://www.instagram.com/toppet.cl" target="_blank">
                                        <IonIcon icon={logoInstagram}></IonIcon>
                                    </IonButton>
                                    <IonButton className="ion-align-self-center" size="small" color="medium" href="https://www.linkedin.com/in/cashay-spa-575ab51aa/" target="_blank">
                                        <IonIcon icon={logoLinkedin}></IonIcon>
                                    </IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                {/*<IonButton color="warning" onClick={() => { printTermal() }}><IonLabel>Imprimir Prueba</IonLabel></IonButton>*/}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonText id='info-version'>v 0.0.15</IonText>


                </IonContent>
            </IonMenu>
        </>
    );
};

export default Menu;