import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import React from "react";
import Login from "./pages/Login";
import AppContextProvider from "./context/App/AppContextProvider";
import Menu from "./components/Menu";
import Pos from "./pages/Pos";
import Reports from "./pages/Reports";
import { setupIonicReact } from "@ionic/react";
import OrderReport from "./pages/OrderReport";
setupIonicReact({
  mode: "md",
});

const App: React.FC = () => (
  <IonApp>
    <AppContextProvider>
      <IonReactRouter>
        <>
          <Menu />
          <IonRouterOutlet id="main-content">
            <Route exact path="/home">
              <Pos />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/pos">
              <Pos />
            </Route>
            <Route exact path="/informes">
              <Reports />
            </Route>
            <Route exact path="/order">
              <OrderReport />
            </Route>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          </IonRouterOutlet>
        </>
      </IonReactRouter>
    </AppContextProvider>
  </IonApp>
);

export default App;
