import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonText, IonToolbar } from '@ionic/react';
import './ProductItem.css';
import { getFormatter, getPriceIVA, imageUrl, sendCustomer } from '../../hooks/functions';
import { Customer } from '../../modals/modals';
import { useContext, useRef, useState } from 'react';
import { alertCircleOutline, cashOutline, checkmarkCircleOutline } from 'ionicons/icons';
import AppContext from '../../context/App/AppContext';
import { wait } from '@testing-library/dom';

interface Props {
    setCustomer: (customer: Customer | null) => void;
    setShowModalCustomerAdd: (option: boolean) => void;
    setIsSearch: (option: boolean) => void;
    setCharging: (option: boolean) => void;
    charging: boolean;
    searchTextFocus: () => void;
}

const AddClient = ({ setCustomer, setShowModalCustomerAdd, setIsSearch, charging, setCharging, searchTextFocus }: Props) => {
    const emailInput = useRef<HTMLIonInputElement>(null);
    const phoneInput = useRef<HTMLIonInputElement>(null);
    const nameInput = useRef<HTMLIonInputElement>(null);
    const lastInput = useRef<HTMLIonInputElement>(null);
    const address1Input = useRef<HTMLIonInputElement>(null);
    const address2Input = useRef<HTMLIonInputElement>(null);
    const countryInput = useRef<HTMLIonInputElement>(null);
    const regionInput = useRef<HTMLIonInputElement>(null);
    const cityInput = useRef<HTMLIonInputElement>(null);
    const postcodeInput = useRef<HTMLIonInputElement>(null);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [update, setUpdate] = useState<number>(0);
    const [formError, setFormError] = useState<boolean>(true);
    const { userAuth, openPresent } = useContext(AppContext);

    const validateEmail = () => {

        setUpdate(update + 1);
    }
    const thereIsAnError = () => {
        console.log(formErrors[1]);
        console.log(formError);

        if (formErrors[0] == "" && formErrors[1] == "" && formErrors[2] == "" && formErrors[3] == "" && formErrors[4] == "" && formErrors[5] == "" && formErrors[6] == "" && formErrors[7] == "" && formErrors[8] == "") {
            setFormError(false);
        }
    }
    const validate = (field: string) => {

        if (field === "email") {
            let email = emailInput.current?.value as string;
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(email)) {
                formErrors[0] = "El email no es válido";
                setFormError(true);
            } else {
                formErrors[0] = "";
            }
        } else
            if (field === "phone") {
                let phone = phoneInput.current?.value as string;
                console.log(phone + " - " + phone.length);

                if (phone.length < 1) {
                    formErrors[1] = "El teléfono es obligatorio";
                    setFormError(true);
                } else {
                    formErrors[1] = "";
                }

            } else
                if (field === "name") {
                    let name = nameInput.current?.value as string;
                    if (name.length < 1) {
                        formErrors[2] = "El nombre es obligatorio";
                        setFormError(true);
                    } else {
                        formErrors[2] = "";
                    }

                } else
                    if (field === "last") {
                        let last = lastInput.current?.value as string;
                        if (last.length < 1) {
                            formErrors[3] = "El apellido es obligatorio";
                            setFormError(true);
                        } else {
                            formErrors[3] = "";
                        }

                    } else
                        if (field === "address1") {
                            let address1 = address1Input.current?.value as string;
                            if (address1.length < 1) {
                                formErrors[4] = "La dirección es obligatoria";
                                setFormError(true);
                            } else {
                                formErrors[4] = "";
                            }

                        } else
                            if (field === "country") {
                                let country = countryInput.current?.value as string;
                                if (country.length < 1) {
                                    formErrors[5] = "El país es obligatorio";
                                    setFormError(true);
                                } else {
                                    formErrors[5] = "";
                                }

                            } else
                                if (field === "region") {
                                    let region = regionInput.current?.value as string;
                                    if (region.length < 1) {
                                        formErrors[6] = "La región es obligatoria";
                                        setFormError(true);
                                    } else {
                                        formErrors[6] = "";
                                    }

                                } else
                                    if (field === "city") {
                                        let city = cityInput.current?.value as string;
                                        if (city.length < 1) {
                                            formErrors[7] = "La ciudad es obligatoria";
                                            setFormError(true);
                                        } else {
                                            formErrors[7] = "";
                                        }

                                    } else
                                        if (field === "postcode") {
                                            let postcode = postcodeInput.current?.value as string;
                                            if (postcode.length < 1) {
                                                formErrors[8] = "El código postal es obligatorio";
                                                setFormError(true);
                                            } else {
                                                formErrors[8] = "";
                                            }

                                        }

        setUpdate(update + 1);
        thereIsAnError();
    }

    const ifEnterKey = (e: string) => {
        if (e === "Enter") {
            //login();
        }
    }
    const saveCustomer = async (option: number) => {
        if (!formError) {
            setCharging(true);
            let email = emailInput.current?.value as string;
            let phone = phoneInput.current?.value as string;
            let name = nameInput.current?.value as string;
            let last = lastInput.current?.value as string;
            let address1 = address1Input.current?.value as string;
            let address2 = address2Input.current?.value as string;
            let country = countryInput.current?.value as string;
            let region = regionInput.current?.value as string;
            let city = cityInput.current?.value as string;
            let postcode = postcodeInput.current?.value as string;
            await sendCustomer("Bearer " + userAuth?.token as string, email, phone, name, last, address1, address2, country, region, city, postcode)
                .then((customer) => {
                    console.log(customer);
                    if (option == 1) {
                        setIsSearch(false);
                        setShowModalCustomerAdd(false);
                        searchTextFocus();
                    } else if (option == 2) {
                        setCustomer(customer.data);
                        setIsSearch(false);
                        setShowModalCustomerAdd(false);
                        searchTextFocus();
                    } else {
                        if (emailInput.current)
                            emailInput.current.value = '';
                        if (phoneInput.current)
                            phoneInput.current.value = "";
                        if (nameInput.current)
                            nameInput.current.value = "";
                        if (lastInput.current)
                            lastInput.current.value = "";
                        if (address1Input.current)
                            address1Input.current.value = "";
                        if (address2Input.current)
                            address2Input.current.value = "";
                        if (countryInput.current)
                            countryInput.current.value = "";
                        if (regionInput.current)
                            regionInput.current.value = "";
                        if (cityInput.current)
                            cityInput.current.value = "";
                        if (postcodeInput.current)
                            postcodeInput.current.value = "";
                        setUpdate(update + 1);
                        setFormErrors([]);
                        thereIsAnError();
                        setUpdate(update + 1);
                    }
                    openPresent("Se ha guardado el nuevo cliente", false,"success","",3000);

                    //postcodeInput.current.innerText = "";
                }).catch((error) => {
                    openPresent("Posiblemente ya exite un cliente con ese correo.", true,"danger","",3000);
                    console.log(error);
                });
        }
        setCharging(false);
    }
    return (
        <>
            <IonContent id="add-client-form" fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem className={formErrors[0] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Email {formErrors[0] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={emailInput} name="email" type="email" onIonChange={() => validate("email")} onKeyUp={e => ifEnterKey(e.key)} pattern="email" disabled={charging} required></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={formErrors[1] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Teléfono {formErrors[1] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={phoneInput} name="phone" type="tel" onIonChange={() => { validate("phone") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={formErrors[2] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Nombre {formErrors[2] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={nameInput} name="name" type="text" onIonChange={(e) => { validate("name") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={formErrors[3] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Apellidos {formErrors[3] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={lastInput} name="last" type="text" onIonChange={(e) => { validate("last") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={formErrors[4] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Dirección {formErrors[4] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={address1Input} name="address1" type="text" onIonChange={(e) => { validate("address1") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="floating">Dirección 2</IonLabel>
                                <IonInput ref={address2Input} name="address2" type="text" disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={formErrors[5] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">País {formErrors[5] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={countryInput} name="country" type="text" onIonChange={(e) => { validate("country") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={formErrors[6] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Región {formErrors[6] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={regionInput} name="region" type="text" onIonChange={(e) => { validate("region") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={formErrors[7] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Comuna {formErrors[7] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={cityInput} name="city" type="text" onIonChange={(e) => { validate("city") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={formErrors[8] == "" ? "ion-valid" : "ion-invalid"}>
                                <IonLabel position="floating">Código Postal {formErrors[8] == "" ? <IonIcon size="small" icon={checkmarkCircleOutline} /> : <IonIcon size="small" icon={alertCircleOutline} />}</IonLabel>
                                <IonInput ref={postcodeInput} name="postcode" type="number" onIonChange={(e) => { validate("postcode") }} required disabled={charging}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><IonLabel color="danger"><small><IonIcon size="small" icon={alertCircleOutline} /> campo obligatorio</small></IonLabel></IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter>
                <IonToolbar className="ion-padding-start ion-padding-end ion-text-end">
                    <IonButton color="dark" onClick={() => { saveCustomer(3) }} disabled={formError || charging ? true : false}>Guardar</IonButton>
                    <IonButton color="light" onClick={() => { saveCustomer(1) }} disabled={formError || charging ? true : false}>Guardar & Cerrar</IonButton>
                    <IonButton color="warning" onClick={() => { saveCustomer(2) }} disabled={formError || charging ? true : false}>Guardar & Usar</IonButton>

                </IonToolbar>
            </IonFooter>
        </>
    );
};

export default AddClient;