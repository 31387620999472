import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import axios from "axios";
import { getObject, setObject } from "../hooks/storage";
import AppContext from "../context/App/AppContext";
import { Storage } from "@capacitor/storage";
import { LoginErrors } from "../modals/modals";
import "./Login.css";
import {
  errorReturnMessage,
  isVendor,
  returnUser,
} from "../hooks/functions";
import { useHistory } from "react-router-dom";
//import { log } from 'node:console';

const Login: React.FC = () => {
  const { saveUserAuth, openPresent } = useContext(AppContext);
  const emailInput = useRef<HTMLIonInputElement>(null);
  const passwordInput = useRef<HTMLIonInputElement>(null);
  //const [present, dismiss] = useIonToast();
  const history = useHistory();
  const [update, setUpdate] = useState<string>();
  const [text, setText] = useState<string>("Cargando ");
  const [charging, setCharging] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<LoginErrors>({
    emailError: true,
    emailMessage: "Ingrese un email válido",
    passwordError: true,
    passwordMessage: "Ingrese un mínimo de 6 caracteres",
  });

  const init = async () => {
    setUpdate("Iniciando");
    const ret = await Storage.get({ key: "userAuthCashay" });
    if (ret.value) {
      const object = JSON.parse(ret.value);
      const user = returnUser(object);
      setCharging(true);
      setText("Autenticando ");
      if (isVendor(user)) {
        saveUserAuth(user);
        let authToken = "Bearer " + user.token;
        const body = {};
        axios
          .post("https://toppet.cl/wp-json/jwt-auth/v1/token/validate", body, {
            headers: { Authorization: authToken },
          })
          .then((res) => {
            setText("Credenciales correctas. Iniciando ");
            history.replace("/pos");
          })
          .catch((error) => {
            openPresent(error.toString(), true, "danger", "", 3000);
            setCharging(false);
            setText("Ingrese sus datos ");
          });
      } else {
        setCharging(false);
        setText("Ingrese sus datos ");
      }
    } else {
      setCharging(false);
      setText("Ingrese sus datos ");
    }
  };
  useEffect(() => {
    if (!update) {
      init();
    }
    //history.replace();
  }, []);

  const login = () => {
    var errors: LoginErrors;
    errors = formErrors;
    const email = emailInput.current?.value as string;
    const password = passwordInput.current?.value as string;

    if (!errors.emailError && !errors.passwordError) {
      setCharging(true);
      setText("Autenticando ");
      axios
        .post("https://toppet.cl/wp-json/jwt-auth/v1/token", {
          username: email,
          password: password,
        })
        .then((res) => {
          setText("Credenciales correctas. Iniciando ");
          console.log(res);
          

          const user = returnUser(res.data);

          if (isVendor(user)) {
            setObject("userAuthCashay", user);
            saveUserAuth(user);
            history.replace("/pos");
          } else {
            openPresent("not vendor", true, "danger", "", 3000);
            setCharging(false);
            setText("Ingrese sus datos ");
          }
        })
        .catch((error) => {
          openPresent(error.toString(), true, "danger", "", 3000);
          setCharging(false);
          setText("Ingrese sus datos ");
        });
    } else {
      openPresent("not data", true, "danger", "", 3000);
      setCharging(false);
      setText("Ingrese sus datos ");
    }
  };
  /*const showPresent = (text: string) => {
    let errorRes = [];
    errorRes = errorReturnMessage(text);
    present({
      buttons: [{ icon: 'close-circle', handler: () => dismiss() }],
      message: errorRes[1],
      duration: 3000,
      color: "danger",
      header: errorRes[0],
      position: "top"
    });
    setCharging(false);
    setText("Ingrese sus datos ");
  };*/
  const validateEmail = () => {
    const email = emailInput.current?.value as string;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    var errors: LoginErrors = formErrors;
    if (!pattern.test(email)) {
      errors.emailError = true;
      errors.emailMessage = "Ingrese un email válido";
    } else {
      errors.emailError = false;
      errors.emailMessage = "Email válido";
    }
    setFormErrors(errors);
    setUpdate(email);
  };
  const validatePassword = () => {
    const password = passwordInput.current?.value as string;
    var pattern = new RegExp(/^.{6,}$/);
    var errors: LoginErrors = formErrors;
    if (!pattern.test(password)) {
      errors.passwordError = true;
      errors.passwordMessage = "Ingrese un mínimo de 6 caracteres";
    } else {
      errors.passwordError = false;
      errors.passwordMessage = "Al menos 6 caracteres";
    }
    setFormErrors(errors);
    setUpdate(password);
  };
  const ifEnterKey = (e: string) => {
    if (e === "Enter") {
      login();
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCol className="ch-full ch-flex ion-align-items-center ion-justify-content-center ch-flex-flow-col">
          <IonCard className="ch-card ion-align-self-center" color="dark">
            <IonCardHeader>
              <IonImg
                id="logo-login"
                src="assets/images/logo-white-low.png"
              ></IonImg>
              {/*<IonLabel className='ion-text-center'><h1>toppet</h1></IonLabel>*/}
              <IonCardSubtitle className="ion-text-center">
                {" "}
                {text}{" "}
                {charging ? (
                  <IonSpinner className="ch-top-10" name="dots" />
                ) : (
                  ""
                )}
              </IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
          <IonCard className="ch-card ion-align-self-center">
            <IonCardHeader>
              <IonCardSubtitle className="ion-text-center">
                Credenciales
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <form autoComplete="on">
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    ref={emailInput}
                    name="email"
                    type="email"
                    onIonChange={validateEmail}
                    onKeyUp={(e) => ifEnterKey(e.key)}
                    pattern="email"
                    disabled={charging}
                  >
                    {" "}
                  </IonInput>
                </IonItem>
                <span
                  className={
                    formErrors.emailError
                      ? "ch-span ch-error"
                      : "ch-span ch-success"
                  }
                >
                  {formErrors.emailMessage}
                </span>
                <IonItem>
                  <IonLabel position="floating">Contraseña</IonLabel>
                  <IonInput
                    ref={passwordInput}
                    type="password"
                    onIonChange={validatePassword}
                    onKeyUp={(e) => ifEnterKey(e.key)}
                    minlength={6}
                    disabled={charging}
                  >
                    {" "}
                  </IonInput>
                </IonItem>
                <span
                  className={
                    formErrors.passwordError
                      ? "ch-span ch-error"
                      : "ch-span ch-success"
                  }
                >
                  {formErrors.passwordMessage}
                </span>
              </form>
              <IonCardHeader>
                <IonButton
                  expand="full"
                  color="warning"
                  className=""
                  onClick={login}
                  disabled={charging}
                >
                  {charging ? <IonSpinner name="dots" /> : "Entrar"}
                </IonButton>
              </IonCardHeader>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonContent>
    </IonPage>
  );
};

export default Login;
